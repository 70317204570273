import React from 'react'
import { Trans } from 'react-i18next'
import dangerIcon from '../../assets/images/icons/icn-danger-disaster.svg'
import iconFire from '../../assets/images/fireIcon.svg'
import { createPortal } from 'react-dom'
import { disasterType } from '../../appConstants'

const { FIRE, AMMONIA_LEAKS } = disasterType

export function RegisterToDoubleClickToast({
  disasterType,
}: {
  disasterType: string
}) {
  return createPortal(
    <div className="uk-position-fixed disaster-location">
      <div className="uk-flex uk-flex-middle column-gap-60 color-white bg-grey-95 uk-padding-small disaster-location-inner">
        <img
          src={
            disasterType?.includes(FIRE)
              ? iconFire
              : disasterType?.includes(AMMONIA_LEAKS)
              ? dangerIcon
              : null
          }
          width={92}
          className="icn-danger-disaster"
        />
        <span className="title">
          <Trans>TITLES.DOUBLE_CLICK_TO_REGISTER</Trans>
        </span>
      </div>
    </div>,
    document.body
  )
}
