import { useQuery } from '@tanstack/react-query'
import type { Map as MapLibreMap } from 'maplibre-gl'
import { useMemo } from 'react'
import { FETCH_TSUNAMI_AND_EARTHQUAKE_AFTER_MINUTES } from '../../appConstants'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import {
  isEarthquakeDisasterPresent,
  isTsunamiDisasterPresent,
} from '../../utils/disasterRiskManagement/disaster'
import { useLatestDisasterStatus } from './useLatestDisasterStatus'
import {
  useIsCorrectMapCoordinatesResponse
} from './useMapCoordinates'

type Params = {
  setShowConfirmModal?: (val: any) => void
}

export function useTsunamiAndEarthquakeInfo(params: Params) {
  const { currentTenantId } = useAuth()
  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()
  const {
    data: latestDisasterStatus,
    isLoading: latestDisasterLoading,
  } = useLatestDisasterStatus()

  const status = !!latestDisasterStatus?.data.status

  const tsunamiAlreadyPresent = useMemo(
    () => status && isTsunamiDisasterPresent(latestDisasterStatus),
    [latestDisasterStatus, status]
  )
  const earthquakeAlreadyPresent = useMemo(
    () => status && isEarthquakeDisasterPresent(latestDisasterStatus),
    [latestDisasterStatus, status]
  )

  const tsunamiAndEarthquakeBothDisastersAlready =
    status && tsunamiAlreadyPresent && earthquakeAlreadyPresent

  return useQuery({
    queryKey: ['tsunami-and-earthquake', currentTenantId],
    enabled: !!(
      (
        currentTenantId &&
        mapCoordinatesSuccess &&
        !latestDisasterLoading
      ) /* &&
      !tsunamiAndEarthquakeBothPresent */
    ),
    queryFn: () => tsunamiAndEarthQuakeQueryFunction(currentTenantId),
    refetchInterval: FETCH_TSUNAMI_AND_EARTHQUAKE_AFTER_MINUTES * 60 * 1000,
    onSuccess(data) {
      const newEarthquakeRes = data?.earthquakeRes || null
      const newTsunamiRes = data?.tsunamiRes || null

      // if no new disaster comes, hide modal
      if (!newEarthquakeRes && !newTsunamiRes) {
        params.setShowConfirmModal?.(false);
        return;
      }

      // if both disasters already present,
      // no need to show register modal
      if (tsunamiAndEarthquakeBothDisastersAlready) {
        params.setShowConfirmModal?.(false)
        return
      }

      // if either of new disaster comes
      if (
        (newEarthquakeRes && !earthquakeAlreadyPresent) ||
        (newTsunamiRes && !tsunamiAlreadyPresent)
      ) {
        params.setShowConfirmModal?.(true)
        return
      }
    },
  })
}

export async function tsunamiAndEarthQuakeQueryFunction(tenantId: string) {
  const [
    tsunamiPromiseSettled,
    earthquakePromiseSettled,
  ] = await Promise.allSettled([
    DisasterService.fetchTsunamiInfo(tenantId),
    DisasterService.fetchEarthQuakeInfo(tenantId),
  ])

  return {
    tsunamiRes:
      tsunamiPromiseSettled.status == 'fulfilled'
        ? tsunamiPromiseSettled.value.data.tsunami || null
        : null,
    earthquakeRes:
      earthquakePromiseSettled.status == 'fulfilled'
        ? earthquakePromiseSettled.value.data || null
        : null,
  }
}
