import React, { useEffect, useMemo, useState } from 'react'
import { DASH, disasterType } from '../../appConstants'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useLatestDisasterStatus } from '../../hooks/queries/useLatestDisasterStatus'
import {
  getCommaSeparatedFromArr,
  removeDuplicatesArr,
  checkAndSplitByComma,
} from '../../utils/commonUtils'
import { useRegisterDisasterMutation } from '../../hooks/mutation/useRegisterDisasterMutation'
import {
  tsunamiAndEarthQuakeQueryFunction,
  useTsunamiAndEarthquakeInfo,
} from '../../hooks/queries/useTsunamiAndEarthquakeInfo'
import {
  getDisastersDiff,
  isDisasterValNaN,
  isEarthquakeDisasterPresent,
  isFireOrGasDisasterPresent,
  isTsunamiDisasterPresent,
  sortDisasterStrings,
} from '../../utils/disasterRiskManagement/disaster'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import type { PutDisasterPlaceParam } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import type { Map as MapLibreMap } from 'maplibre-gl'
import { useMapCoordinates } from '../../hooks/queries/useMapCoordinates'
import { MapBoundaryUtils } from '../../utils/disasterRiskManagement/map'
import { useDisasterArea } from '../../hooks/queries/useDisasterArea'

const { EARTHQUAKE, TSUNAMI } = disasterType

export function TsunamiAndEarthQuakeInfo({
  mabObject,
}: {
  mabObject: null | MapLibreMap
}) {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const { data: mapCoorDinatesData } = useMapCoordinates()
  const { data: latestDisasterStatus } = useLatestDisasterStatus()
  const status = !!latestDisasterStatus?.data.status

  const tsunamiAlreadyPresent = useMemo(
    () => status && isTsunamiDisasterPresent(latestDisasterStatus),
    [latestDisasterStatus, status]
  )
  const earthquakeAlreadyPresent = useMemo(
    () => status && isEarthquakeDisasterPresent(latestDisasterStatus),
    [latestDisasterStatus, status]
  )

  const { data, isLoading, error } = useTsunamiAndEarthquakeInfo({
    setShowConfirmModal: setShowConfirmModal,
  })
  const earthquakeRes = data?.earthquakeRes || null
  const tsunamiRes = data?.tsunamiRes || null

  useEffect(() => {
    // Reference to the 'style.load' event listener
    let styleLoadListener: any = null
    const mapObject = mabObject ?? null
    const coastLine = mapCoorDinatesData?.data.coast ?? null
    const validCoastline =
      coastLine &&
      Object.keys(coastLine).length > 0 &&
      coastLine.coordinates &&
      coastLine.coordinates.length > 0

    if (
      !(
        mapObject &&
        coastLine &&
        validCoastline &&
        tsunamiAlreadyPresent
      )
    ) {
      return
    }

    const safelyAddCoastline = () => {
      MapBoundaryUtils.addCoastLine(mapObject, coastLine)
      MapBoundaryUtils.startBlinking(mapObject)
    }

    // either tsunami or earthquake disaster should be present
    // map object should be defined and tenant boundary should come from the API

    if (mapObject.isStyleLoaded()) {
      safelyAddCoastline()
    } else {
      styleLoadListener = () => {
        safelyAddCoastline()
      }
      mapObject.once('styledata', styleLoadListener)
    }

    return function () {
      MapBoundaryUtils.stopBlinking(mapObject)
      if (mapObject && mapObject.isStyleLoaded()) {
        MapBoundaryUtils.removeLineString(mapObject)
      }

      if (styleLoadListener) {
        mapObject.off('styledata', styleLoadListener)
      }
    }
  }, [
    mabObject,
    mapCoorDinatesData?.data.coast,
    tsunamiAlreadyPresent,
  ])

  return <Modal
      earthquakeRes={earthquakeRes}
      tsunamiRes={tsunamiRes}
      showConfirmModal={showConfirmModal}
      hideModal={() => setShowConfirmModal(false)}
    />
}

const Modal = ({
  hideModal,
  earthquakeRes,
  tsunamiRes,
  showConfirmModal
}: {
  showConfirmModal: boolean
  hideModal: () => void
  earthquakeRes: Awaited<
    ReturnType<typeof tsunamiAndEarthQuakeQueryFunction>
  >['earthquakeRes']
  tsunamiRes: Awaited<
    ReturnType<typeof tsunamiAndEarthQuakeQueryFunction>
  >['tsunamiRes']
}) => {
  const { t } = useTranslation()
  const { isLoading: tsunamiAndEarthquakeInfoLoading } = useTsunamiAndEarthquakeInfo({})

  const seismicIntesityRes = earthquakeRes?.seismic_intensity
  const earthquakeTimeOfOccurrence = earthquakeRes?.earthquake.origin_time
  const earthquakeEpicenterArea = earthquakeRes?.earthquake.area_epicenter_name
  const earthquakeMagnitude = earthquakeRes?.earthquake.magnitude
  const earthquakeAreaName = seismicIntesityRes?.point_seismic_intensity_name || seismicIntesityRes?.area_information_city_name || seismicIntesityRes?.area_forecast_local_e_name || seismicIntesityRes?.area_information_prefecture_earthquake_name  
  const seismic_intensity = earthquakeRes?.seismic_intensity?.seismic_intensity
  const tsunamiMaxHeight = tsunamiRes?.max_height

  const tsunamiEstimatedArrivalTime =
    tsunamiRes?.forecast_point_tsunami_first_height_arrival_time || tsunamiRes?.first_height_arrival_time
  const tsunamiCause = earthquakeRes?.earthquake?.area_epicenter_name

  const { data: latestDisasterStatus, isLoading: latestDisasterStatusLoading } = useLatestDisasterStatus()
  const { data: disasterAreaData } = useDisasterArea()
  const anyDisasterPresent = !!latestDisasterStatus?.data.status
  const currentDisasters = anyDisasterPresent
    ? latestDisasterStatus?.data.disaster_type
    : undefined
  const disasterId = anyDisasterPresent
    ? latestDisasterStatus?.data.disaster_id
    : undefined
  const earthquakeDisasterPresent =
    anyDisasterPresent && isEarthquakeDisasterPresent(latestDisasterStatus)
  const tsunamiDisasterPresent =
    anyDisasterPresent && isTsunamiDisasterPresent(latestDisasterStatus)

  // list of NEW disaster (that WILL be)
  const allNewDisasterStr = useMemo(() => {
    const earthquakeDataPresent = !!earthquakeRes
    const tsunamiDataPresent = !!tsunamiRes
    const allDisastersArr = checkAndSplitByComma(currentDisasters ?? '')
    if (earthquakeDataPresent) {
      allDisastersArr.push(EARTHQUAKE)
    }
    if (tsunamiDataPresent) {
      allDisastersArr.push(TSUNAMI)
    }
    return getCommaSeparatedFromArr(removeDuplicatesArr(allDisastersArr))
  }, [currentDisasters, earthquakeRes, tsunamiRes])

  const registerDisaster = useRegisterDisasterMutation(function (data) {
    const fireOrGasPresent = isFireOrGasDisasterPresent(latestDisasterStatus)
    const fireOrDisasterLocated = fireOrGasPresent && (disasterAreaData?.data?.hazard_point?.geometry ?? undefined)

    // If fire or ammonia is "created but not located yet", skip calling hazard points 
    if (fireOrGasPresent && !fireOrDisasterLocated) {
      return;
    }

    // start python process by calling hazard points API
    const disaster_id = data?.data?.disaster_id
    disasterPlaceMutation.mutate({
      disasterId: disaster_id,
      tenantId: currentTenantId,
      status: true,
      latitude: null,
      longitude: null,
      disaster_type: sortDisasterStrings(allNewDisasterStr),
    })
  })
  const queryClient = useQueryClient()

  const vals = useMemo(() => {
    return [
      {
        label: !earthquakeDisasterPresent && earthquakeTimeOfOccurrence
        ? `${t('LABELS.TIME_OF_OCCURRENCE')} ${format(
            new Date(earthquakeTimeOfOccurrence),
            'HH:mm'
          )}`
        : '',
        show: !earthquakeDisasterPresent && earthquakeTimeOfOccurrence,
      },
      {
        label: !earthquakeDisasterPresent && earthquakeEpicenterArea
        ? `${t('LABELS.EPICENTER')} ${earthquakeEpicenterArea}`
        : '',
        show: !earthquakeDisasterPresent && earthquakeEpicenterArea
      },
      {
        label: !earthquakeDisasterPresent && earthquakeMagnitude
        ? `${t('LABELS.MAGNITUDE')} ${!isDisasterValNaN(earthquakeMagnitude) ? earthquakeMagnitude : DASH}`
        : '',
        show: !earthquakeDisasterPresent && earthquakeMagnitude
      },
      {
        label: (!earthquakeDisasterPresent && (earthquakeAreaName || seismic_intensity)) ? `${earthquakeAreaName} ${t("LABELS.SEISMIC_INTENSITY")}${seismic_intensity ?? DASH}` : "",
        show: (!earthquakeDisasterPresent && (earthquakeAreaName || seismic_intensity))
      },
      {
        label: !tsunamiDisasterPresent && tsunamiRes
        ? tsunamiRes.tsunami_warning_name
        : '',
        show: !tsunamiDisasterPresent && tsunamiRes
      },
      {
        label: !tsunamiDisasterPresent && tsunamiEstimatedArrivalTime
        ? `${t('LABELS.EXPECTED_ARRIVAL_TIME')} ${format(
            new Date(tsunamiEstimatedArrivalTime),
            'HH:mm'
          )}`
        : '',
        show: !tsunamiDisasterPresent && tsunamiEstimatedArrivalTime
      },
      {
        label: (!tsunamiDisasterPresent && tsunamiRes && !isDisasterValNaN(tsunamiMaxHeight)) ? `${t('LABELS.TSUNAMI_HEIGHT')} ${tsunamiMaxHeight}` : `${t('LABELS.HEIGHT')} ${DASH}`,
        show: !tsunamiDisasterPresent && tsunamiRes
      },
      {
        label: (!tsunamiDisasterPresent && earthquakeDisasterPresent && tsunamiCause) ? `${tsunamiCause}` : "",
        show: !tsunamiDisasterPresent && earthquakeDisasterPresent && tsunamiCause
      }
    ].filter((item) => item.show).map(item => item.label)
  }, [earthquakeAreaName, earthquakeDisasterPresent, earthquakeEpicenterArea, earthquakeMagnitude, earthquakeTimeOfOccurrence, seismic_intensity, t, tsunamiCause, tsunamiDisasterPresent, tsunamiEstimatedArrivalTime, tsunamiMaxHeight, tsunamiRes])

  // if every item in list is empty
  // this fixes showing earthquake or tsunami modal if already disaster present
  const allEitherNullOrDash = useMemo(() => vals.every(item => (!item || (item === DASH) || item.includes("NaN"))), [vals])

  const { currentTenantId } = useAuth()

  const disasterPlaceMutation = useMutation({
    mutationFn: (
      body: PutDisasterPlaceParam & {
        tenantId: string
        disasterId: number
        status: boolean
      }
    ) =>
      DisasterService.putDisasterPlace(body.tenantId, body.disasterId, {
        latitude: body.latitude,
        longitude: body.longitude,
        status: body.status,
        disaster_type: body.disaster_type,
      }),
    onSuccess(response, variables, context) {
      // refetch latest disaster
      queryClient.invalidateQueries({
        queryKey: ['disaster-status', currentTenantId],
      })
      // refetch disaster Area (to refresh data and redraw disaster marker)
      queryClient.invalidateQueries({
        queryKey: ['disaster-area', currentTenantId, disasterId],
      })
    },
  })

  const showTsunamiNotPresentLabel = !tsunamiRes && !tsunamiDisasterPresent && !(earthquakeRes?.earthquake.jma_reports_id === null);

  // if no info present, show nothing
  if (!showConfirmModal || latestDisasterStatusLoading || tsunamiAndEarthquakeInfoLoading || allEitherNullOrDash) {
    return null
  }

  return createPortal(
    <div className="modal-area without-overlay-bg modal-tsunami-earthquake uk-box-shadow-xlarge">
      <div className="modal-dialog uk-text-center color-white bg-grey-80">
        <div className="bg-grey-95 modal-padding uk-margin-small-bottom uk-border-rounded">
          <div className="warning-area uk-flex uk-flex-middle column-gap-22">
            <div className="square-repetition" />
            <div className="warning-heading color-red line-height-1">
              WARNING
            </div>
            <div className="square-repetition" />
          </div>
          <div className="modal-title-3 uk-margin-medium-top uk-margin-medium-bottom modal-h2-title">
            {
              // if none of two are present
              !(earthquakeDisasterPresent || tsunamiDisasterPresent) &&
              earthquakeRes &&
              tsunamiRes ? (
                <Trans>LABELS.EARTHQUAKE_AND_TSUNAMI_INFO_OBTAINED</Trans>
              ) : // if earthquake is not already presesnt
              !earthquakeDisasterPresent && earthquakeRes ? (
                <Trans>LABELS.EARTHQUAKE_INFO_OBTAINED</Trans>
              ) : // if tsunami is not already present
              !tsunamiDisasterPresent && tsunamiRes ? (
                <Trans>LABELS.TSUNAMI_INFO_OBTAINED</Trans>
              ) : null
            }
          </div>
          <div className="modal-pill-container">
            {vals.map((item) => {
              if (!item) {
                return null
              }
              return (
                <div key={item} className="modal-pill bg-grey-80">
                  {item}
                </div>
                
              )
            })}
            {
              (vals.length % 2 !== 0) ? <div className='modal-pill'></div> : null
            }
          </div>
            {
              showTsunamiNotPresentLabel ? (
                <p className='tsunami-present-label'>
                  <Trans>TITLES.NO_TSUNAMI_WITH_EARTHQUAKE</Trans>
                </p>
              ) : null
            }
        </div>

        <div className="bg-grey-95 modal-padding uk-border-rounded">
          <div
            className={
              cn()
              /* variant === 'large' &&  'modal-body uk-padding-medium-left'*/
            }
          >
            {/* {messageTitle ? ( */}
            <h2
              className={cn(
                // variant === 'large'
                // ? 'modal-title uk-margin-small-top uk-margin-remove-bottom'
                /* :  */ 'modal-title-3 uk-margin-medium-top uk-margin-medium-bottom modal-h2-title'
                // !messageBody && 'modal-h2-title-lg'
              )}
            >
              避難誘導サポートを開始しますか？
            </h2>
            {/* ) : null} */}
            {/* {messageBody ? ( */}

            {/* ) : null} */}
            <div
              className={cn(
                // variant === 'large'
                // ? 'modal-footer mb-30 uk-flex uk-flex-middle uk-flex-around'
                /* :  */ 'modal-footer uk-margin-medium-bottom uk-flex uk-flex-middle uk-flex-around'
              )}
            >
              <button
                data-id="yesButton"
                type="button"
                className="uk-button uk-button-link color-white modal-pill bg-grey-80"
                onClick={async () => {
                  if (currentDisasters !== allNewDisasterStr) {
                    const res = registerDisaster.mutate({
                      disaster_type: allNewDisasterStr,
                      practice_flag: false,
                      new_disaster: getDisastersDiff(
                        allNewDisasterStr,
                        currentDisasters ?? ''
                      ),
                    })

                    if (!disasterId) {
                      hideModal()
                      return
                    }
                  }

                  hideModal()
                }}
              >
                <Trans>BUTTONS.CONFIRM_YES</Trans>
              </button>
              <button
                data-id="noButton"
                className="uk-button uk-button-link uk-modal-close color-white modal-pill bg-grey-80"
                type="button"
                onClick={hideModal}
              >
                {/* {noText || <Trans>BUTTONS.CONFIRM_NO</Trans>} */}
                <Trans>BUTTONS.CONFIRM_NO</Trans>
              </button>
            </div>
          </div>

          <div className="warning-area">
            <div className="square-repetition" />
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}
