import { useQuery } from '@tanstack/react-query'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { useLatestDisasterStatus } from './useLatestDisasterStatus'
import { reFetchHazardAreaInterval } from '../../pages/newDashboard/common/refetchHazardArea'
import { routesConstant } from '../../appConstants'

export function useDisasterArea() {
  const { currentTenantId } = useAuth()
  const { data: latestDisasterStatus } = useLatestDisasterStatus()
  const disasterId = latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined

  const isQRCodePage =
    window.location.pathname === routesConstant.APPLICATION_QR_CODE

  return useQuery({
    queryKey: ['disaster-area', currentTenantId, disasterId],
    enabled: !!currentTenantId && !!disasterId && !isQRCodePage,
    queryFn: () =>
      DisasterService.fetchDisasterArea(currentTenantId, disasterId as number),
    refetchInterval: reFetchHazardAreaInterval,
  })
}
