import Envs from '../../Envs'
import { urlConstant, urlParametersConstant } from '../../appConstants'
import type {
  FetchDisasterStatusRes,
  PostDisasterStatusRes,
  PutDisasterStatusRes,
  FetchUserSafetyListRes,
  FetchEvacuationSiteFeatureRes,
  FetchUserLocationsRes,
  FetchWeatherLocationsRes,
  FetchUserEvacuationStatus,
  FetchTenantMapCoordinates,
  HazardAreaBody,
  DisasterItemsResponse,
  RegisterDisasterItemsPayload,
  TSUNAMI_RESPONSE,
  EARTHQUAKE_RESPONSE,
} from '../../data/types/DisasterRiskManagement'
import { sortDisasterStrings } from '../../utils/disasterRiskManagement/disaster'
import AxiosHttpService from '../AxiosHttpService'
import { AxiosResponse } from 'axios'

export type PostDisasterStatusParam = {
  practice_flag: boolean
  disaster_type: string
  new_disaster?: string
}
export type PutDisasterStatusParam = {
  status: boolean
}

export type PutDisasterPlaceParam = {
  status?: boolean
  longitude?: number | null
  latitude?: number | null
  disaster_type: string | null
}

export type PutDisasterPlaceRes = {
  "disaster_id": number,
  "status": boolean
} | undefined

export type AddDisasterMarkerItem = {
  latitude: number
  longitude: number
  disaster_item_type: string
}

const apiUrl = Envs.API_URL

const {
  DISASTERS,
  HAZARD_POINTS,
  USER_SAFETY_LIST,
  EVACUATION_SITES,
  WEATHER,
  DISASTER_ITEMS,
  USERS,
  EVACUATION,
  HISTORY,
  LATEST,
  MAP,
  LOCATIONS,
  QR_CODE,
  TSUNAMI,
  EARTHQUAKE,
} = urlConstant
const { TENANTS } = urlParametersConstant

export const DisasterService = {
  fetchDisasterStatus: async (
    tenant_id: string
  ): Promise<AxiosResponse<FetchDisasterStatusRes>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTERS}/${LATEST}`
    )
  },
  postDisasterStatus: async (
    body: PostDisasterStatusParam,
    tenant_id: string
  ): Promise<AxiosResponse<PostDisasterStatusRes>> => {
    return (await AxiosHttpService()).Post(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTERS}`,
      {
        ...body,
        disaster_type: sortDisasterStrings(body?.disaster_type),
        new_disaster: sortDisasterStrings(body?.new_disaster ?? "") || null
      }
    )
  },
  putDisasterStatus: async (
    body: PutDisasterStatusParam,
    tenant_id: string,
    disaster_id: number
  ): Promise<AxiosResponse<PutDisasterStatusRes>> => {
    return (await AxiosHttpService()).Put(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTERS}/${disaster_id}`,
      body
    )
  },
  putDisasterPlace: async (
    tenant_id: string,
    disaster_id: number,
    body: PutDisasterPlaceParam
  ): Promise<AxiosResponse<PutDisasterPlaceRes>> => {
    return (await AxiosHttpService()).Put(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTERS}/${disaster_id}/${HAZARD_POINTS}`,
      body
    )
  },
  fetchUserSafetyList: async (
    disaster_id: number,
    tenant_id: string
  ): Promise<AxiosResponse<FetchUserSafetyListRes>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTERS}/${disaster_id}/${USER_SAFETY_LIST}`
    )
  },
  fetchAllEvacationSites: async (
    tenant_id: string
  ): Promise<AxiosResponse<FetchEvacuationSiteFeatureRes>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenant_id}/${EVACUATION_SITES}`
    )
  },
  fetchUserLocations: async (
    tenantId: string
  ): Promise<AxiosResponse<FetchUserLocationsRes>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenantId}/${USERS}/${LOCATIONS}`
    )
  },
  fetchWeatherLocations: async (
    tenant_id: string
  ): Promise<AxiosResponse<FetchWeatherLocationsRes>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenant_id}/${WEATHER}`
    )
  },
  fetchDisasterArea: async (
    tenant_id: string,
    disaster_id: number
  ): Promise<AxiosResponse<HazardAreaBody>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTERS}/${disaster_id}`
    )
  },
  fetchMapCoordinates: async (
    tenant_id: string
  ): Promise<AxiosResponse<FetchTenantMapCoordinates>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenant_id}/${MAP}`
    )
  },
  fetchPreventionItems: async (
    tenant_id: string
  ): Promise<AxiosResponse<DisasterItemsResponse>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTER_ITEMS}`
    )
  },
  postPreventionsItems: async (
    tenant_id: string,
    body: AddDisasterMarkerItem
  ): Promise<AxiosResponse<any>> => {
    return (await AxiosHttpService()).Post(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTER_ITEMS}`,
      body
    )
  },
  deletePreventionItems: async (
    tenant_id: string,
    disaster_item_id: number
  ): Promise<AxiosResponse<any>> => {
    return (await AxiosHttpService()).Delete(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTER_ITEMS}/${disaster_item_id}`
    )
  },

  postPreventionsItemsDisasterBased: async (
    tenant_id: string,
    disaster_id: number,
    body: RegisterDisasterItemsPayload
  ): Promise<AxiosResponse<any>> => {
    return (await AxiosHttpService()).Post(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTERS}/${disaster_id}/${DISASTER_ITEMS}`,
      body
    )
  },
  fetchDisasterBasePreventionItems: async (
    tenant_id: string,
    disaster_id: number
  ): Promise<AxiosResponse<DisasterItemsResponse>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTERS}/${disaster_id}/${DISASTER_ITEMS}`
    )
  },

  deletePreventionBasedItems: async (
    tenant_id: string,
    disaster_item_id: number,
    disaster_id: number
  ): Promise<AxiosResponse<any>> => {
    return (await AxiosHttpService()).Delete(
      `${apiUrl}${TENANTS}/${tenant_id}/${DISASTERS}/${disaster_id}/${DISASTER_ITEMS}/${disaster_item_id}`
    )
  },

  fetchEvacuationStatus: async (
    tenantId: string,
    disaster_id: number
  ): Promise<AxiosResponse<FetchUserEvacuationStatus>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenantId}/${DISASTERS}/${disaster_id}/${USERS}/${EVACUATION}/${HISTORY}`
    )
  },
  async fetchTsunamiInfo(tenantId: string): Promise<AxiosResponse<TSUNAMI_RESPONSE>> {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenantId}/${TSUNAMI}`
    )
  },
  async fetchEarthQuakeInfo(tenantId: string): Promise<AxiosResponse<EARTHQUAKE_RESPONSE>> {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenantId}/${EARTHQUAKE}`
    )
  }
}
