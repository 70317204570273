import React, { useMemo } from 'react'
import { t } from 'i18next'
import { appConstant, DASH, userStatus } from '../../appConstants'
import {
  FetchUserLocationsRes,
  FetchUserSafetyListRes,
  Operator,
  User,
  UserLocationFeature,
} from '../../data/types/DisasterRiskManagement'
import type { Map as MapLibreMap, Popup } from 'maplibre-gl'
import { getUserStatusIcon } from '../../utils/commonUtils'
import maplibregl from 'maplibre-gl'
import i18n from '../../translations/i18n'
import { useLatestDisasterStatus } from '../queries/useLatestDisasterStatus'

const userSafetyListFilterType = {
  STATUS: 'status',
  AFFILIATION: 'affiliation',
  ALL: 'all',
  BLANK: 'blank',
  EVACUATION_STATUS: 'evacuation_status',
} as const

const { SERIOUS_INJURY, MINOR_INJURY, SAFE, NOT_ANSWERED } = userStatus

const { STATUS } = userSafetyListFilterType
const { USER_ID } = appConstant

export type SafetyListProps = {
  userSafetyListData: FetchUserSafetyListRes | undefined
  userLocationsData: FetchUserLocationsRes | undefined
  // evacuationHistoryData: FetchUserEvacuationStatus | undefined
  evacuationHistoryError: Error | null
  // tableData: FetchUserSafetyListRes
  // setTableData: React.Dispatch<React.SetStateAction<FetchUserSafetyListRes>>
  mapObject: null | MapLibreMap
}

type MapMouseEvent = (e: any) => void

export const useUserMapLocation = ({
  userSafetyListData,
  userLocationsData,
  // evacuationHistoryData,
  evacuationHistoryError,
  // tableData,
  // setTableData,
  mapObject,
}: SafetyListProps) => {
  const { data: latestDisasterStatus } = useLatestDisasterStatus()

  const isDisasterRegistered = !!(latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined)

  const userLocations = useMemo(() => {
    if (
      typeof userLocationsData === 'object' &&
      userLocationsData?.users &&
      userLocationsData?.users.length >= 0
    ) {
      const locationsArr = userLocationsData?.users.map((user: User) => ({
        longitude: user?.geometry?.coordinates?.[0],
        latitude: user?.geometry?.coordinates?.[1],
        user_id: user.user_id,
      }))
      return locationsArr
    } else {
      return []
    }
  }, [userLocationsData])

  const userLocationOnMap: UserLocationFeature[] = useMemo(() => {
    if (userSafetyListData?.length && userLocations.length) {
      const geoType = convertResToGeoType({ userLocations, userSafetyListData })
      return geoType
    } else {
      return []
    }
  }, [userLocations, userSafetyListData])

  React.useEffect(() => {
    // Reference to the 'style.load' event listener
    let styleLoadListener: any = null
    if (
      !(
        (
          mapObject && isDisasterRegistered
        ) /* && userLocationOnMap && userLocationOnMap.length > 0 */
      )
    ) {
      return
    }
    let onMouseEnterUnClustered: MapMouseEvent | undefined,
      onMouseLeaveUnClustered: MapMouseEvent | undefined,
      onMouseEnterClusters: MapMouseEvent | undefined,
      onMouseLeaveClusters: MapMouseEvent | undefined,
      userMarkerPopup: Popup | undefined,
      usersClusterPopup: Popup | undefined

    // const fn = () => {
    const safelyAddUserMarkers = () => {
      // return updateMapWithLocationData({ mapObject, userLocationOnMap })
      const selectedFeatures: UserLocationFeature[] = userLocationOnMap

      userMarkerPopup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'custom-popup-user',
      })

      usersClusterPopup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'custom-popup',
      })

      const selectedUsersSource = mapObject.getSource(
        'user-locations'
      ) as maplibregl.GeoJSONSource

      // if (selectedUsersSource) {
      //   selectedUsersSource.setData({
      //     type: 'FeatureCollection',
      //     features: selectedFeatures as any,
      //   })
      // } else {

      mapObject.addSource('user-locations', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: selectedFeatures,
        },
        cluster: true,
        clusterMaxZoom: 15,
        clusterRadius: 50,
      })

      // Add a layer for the cluster count
      if (!mapObject.getLayer(`clusters`)) {
        mapObject.addLayer({
          id: 'clusters',
          type: 'symbol',
          source: 'user-locations',
          filter: ['has', 'point_count'],
          layout: {
            'icon-image': 'users-cluster',
            // 'icon-size': 1,
            'icon-size': [
              'step',
              ['get', 'point_count'],
              1, // Base size when point_count <= 10
              10,
              1.5, // Size 1.5 for clusters with point_count <= 50
              50,
              3, // Size 3 for clusters with point_count > 50
              100,
              5,
            ],
            'symbol-placement': 'point', // Ensure symbols are placed at their geographical position
            'icon-allow-overlap': true, // Allow icons to overlap
            'text-allow-overlap': true,
          },
        })
      }

      // Add user location marker layer
      if (!mapObject.getLayer(`user-locations-unclustered-icon`)) {
        mapObject.addLayer({
          id: 'user-locations-unclustered-icon',
          type: 'symbol',
          source: 'user-locations',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': [
              'match',
              ['get', 'status'],
              SAFE,
              'user-green-location',
              MINOR_INJURY,
              'user-orange-location',
              SERIOUS_INJURY,
              'user-red-location',
              NOT_ANSWERED,
              'user-grey-location',
              'user-grey-location', // Fallback if status doesn't match any value
            ],
            'icon-size': 1,
            'symbol-placement': 'point', // Ensure symbols are placed at their geographical position
            'icon-allow-overlap': true, // Allow icons to overlap
            'text-allow-overlap': true, // If you have associated text, allow it to overlap as well
          },
        })
      }

      onMouseEnterUnClustered = (e: any) => {
        const coordinates = e?.features[0]?.geometry['coordinates']
        // @TODO: Need to update the design properly
        const data = `<div class="user-location-popup"><div class="user-status-icon-container"><img src=${getUserStatusIcon(
          e?.features[0]?.properties?.status
        )} height="40" width="40"/></div><div><strong>${
          e?.features[0]?.properties?.partnerCompany || i18n.t('LABELS.OTHER')
        } : </strong>
          <span>${e?.features[0]?.properties?.userName || DASH}</span></div></div>`

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        userMarkerPopup?.setLngLat(coordinates).setHTML(data).addTo(mapObject)
      }

      // on hover show user marker details
      mapObject.on(
        'mouseenter',
        `user-locations-unclustered-icon`,
        onMouseEnterUnClustered
      )

      onMouseLeaveUnClustered = () => {
        userMarkerPopup?.remove()
      }

      // on mouse leave remove popup
      mapObject.on(
        'mouseleave',
        `user-locations-unclustered-icon`,
        onMouseLeaveUnClustered
      )

      onMouseEnterClusters = async (e: any) => {
        const sourceId = 'user-locations'
        const clusterId = e?.features[0]?.properties?.cluster_id
        const coordinates = e?.features[0]?.geometry?.coordinates
        if (!coordinates) return
        //get User information from the cluster
        const clusterSource = mapObject.getSource(
          sourceId
        ) as maplibregl.GeoJSONSource

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        clusterSource.getClusterLeaves(
          clusterId,
          Infinity,
          0,
          (error, clusterLeaves) => {
            if (error) {
              console.error(error)
              // resolve([])
            } else {
              if (clusterLeaves) {
                const clusterUserStatusElement = getClusterUserStatusHtml(
                  clusterLeaves as any
                )
                if (clusterUserStatusElement) {
                  usersClusterPopup
                    ?.setLngLat(coordinates)
                    .setHTML(clusterUserStatusElement)
                    .addTo(mapObject)
                }
              }
            }
          }
        )
      }

      // on hover on cluster show cluster info
      mapObject.on('mouseenter', 'clusters', onMouseEnterClusters)

      onMouseLeaveClusters = () => {
        if (usersClusterPopup) {
          usersClusterPopup.remove() // Remove the popup
        }
      }

      mapObject.on('mouseleave', 'clusters', onMouseLeaveClusters)
      // }
    }

    if (mapObject.isStyleLoaded()) {
      safelyAddUserMarkers()
    } else {
      // Store the reference to the 'style.load' event listener
      styleLoadListener = () => {
        safelyAddUserMarkers()
      }
      mapObject.once('style.load', styleLoadListener)
    }
    // }

    return () => {
      // clean up layers and clusters
      if (mapObject && mapObject.isStyleLoaded()) {
        mapObject.getLayer('clusters') && mapObject.removeLayer('clusters')
        mapObject.getLayer('user-locations-unclustered') &&
          mapObject.removeLayer('user-locations-unclustered')
        mapObject.getLayer('user-locations-unclustered-icon') &&
          mapObject.removeLayer('user-locations-unclustered-icon')
        mapObject.getSource('user-locations') &&
          mapObject.removeSource('user-locations')
      }

      // cleanup popup events
      if (onMouseEnterUnClustered) {
        mapObject.off(
          'mouseenter',
          `user-locations-unclustered-icon`,
          onMouseEnterUnClustered
        )
      }

      if (onMouseLeaveUnClustered) {
        mapObject.off(
          'mouseleave',
          `user-locations-unclustered-icon`,
          onMouseLeaveUnClustered
        )
      }

      if (onMouseEnterClusters) {
        mapObject.off('mouseenter', 'clusters', onMouseEnterClusters)
      }

      if (onMouseLeaveClusters) {
        mapObject.off('mouseleave', 'clusters', onMouseLeaveClusters)
      }

      // remove created objects
      if (userMarkerPopup) {
        userMarkerPopup.remove()
      }
      if (usersClusterPopup) {
        usersClusterPopup.remove()
      }

      if (styleLoadListener) {
        mapObject.off('style.load', styleLoadListener)
      }
    }
  }, [userLocationOnMap, mapObject, isDisasterRegistered])
}

function convertResToGeoType({
  userLocations,
  userSafetyListData,
}: {
  userLocations: Operator[]
  userSafetyListData: SafetyListProps['userSafetyListData']
}) {
  const geoData: UserLocationFeature[] = []
  userLocations.map((item) => {
    // const user = filteredUserSafetyList?.find(
    const user = userSafetyListData?.find(
      (user) => user?.user_id?.toLowerCase() === item?.user_id?.toLowerCase()
    )
    if (user) {
      geoData.push({
        type: 'Feature',
        properties: {
          id: user.user_id,
          [USER_ID]: user.user_id,
          [STATUS]: user.user_status ?? NOT_ANSWERED,
          userName: user.user_name,
          partnerCompany: user.company ?? '-',
        },
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude],
        },
      })
    }
  })
  return geoData
}

function getClusterUserStatusHtml(
  clusterLeaves: maplibregl.MapGeoJSONFeature[]
) {
  const clusterUserStatus: any = {}
  //user status count calculation
  clusterLeaves.map((cluster: any) => {
    const status = cluster?.properties?.status
    if (status) {
      clusterUserStatus[status] = (clusterUserStatus[status] || 0) + 1
    }
  })
  const userStatuses = Object.values(userStatus) //get status value from object

  //return html with status count
  const html = userStatuses
    .map((value: string, idx: number) => {
      return `<p><span class=${
        idx === userStatuses.length - 1 ? 'mr-custom' : 'mr-20'
      }>${t(`LABELS.${value}`)}</span> :<span class="mr-10"></span> <span>${
        (clusterUserStatus[value] || 0) + ' ' + t('LABELS.PERSON')
      }</span></p>`
    })
    .join('')
  return `<div class="cluster-content-container">${html}</div>`
}

async function getClusterLeaves(
  clusterId: number,
  clusterSource: maplibregl.GeoJSONSource
) {
  return new Promise<maplibregl.MapGeoJSONFeature[]>((resolve) => {
    // Get the cluster hierarchy
    clusterSource.getClusterLeaves(
      clusterId,
      Infinity,
      0,
      (error, features: any) => {
        if (error) {
          console.error(error)
          resolve([])
        } else {
          resolve(features)
        }
      }
    )
  })
}
