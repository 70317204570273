import type { AxiosResponse } from "axios";
import { FetchDisasterStatusRes } from "../../data/types/DisasterRiskManagement";
import { disasterType } from "../../appConstants";
import { checkAndSplitByComma, getCommaSeparatedFromArr, removeDuplicatesArr } from "../commonUtils";

export function isTsunamiDisasterPresent(latestDisasterStatus: AxiosResponse<FetchDisasterStatusRes, any> | undefined): boolean {
  const currentDisasters = latestDisasterStatus?.data.status ? latestDisasterStatus?.data.disaster_type : undefined;
  return !!currentDisasters && currentDisasters.includes(disasterType.TSUNAMI)
}

export function isEarthquakeDisasterPresent(latestDisasterStatus: AxiosResponse<FetchDisasterStatusRes, any> | undefined): boolean {
  const currentDisasters = latestDisasterStatus?.data.status ? latestDisasterStatus?.data.disaster_type : undefined;
  return !!currentDisasters && currentDisasters.includes(disasterType.EARTHQUAKE)
}

export function getCurrentDisasters(latestDisasterStatus: AxiosResponse<FetchDisasterStatusRes, any> | undefined): string | undefined {
  const currentDisasters = latestDisasterStatus?.data.status ? latestDisasterStatus?.data.disaster_type : undefined;
  return currentDisasters
}

export function isFireOrGasDisasterPresent(latestDisasterStatus: AxiosResponse<FetchDisasterStatusRes, any> | undefined): boolean {
  const currentDisasters = latestDisasterStatus?.data.status ? latestDisasterStatus?.data.disaster_type : undefined;
  return !!currentDisasters && (currentDisasters.includes(disasterType.FIRE) || currentDisasters.includes(disasterType.AMMONIA_LEAKS))
}

const preferenceOrder = ['FIRE', 'AMMONIA', 'EARTHQUAKE', 'TSUNAMI'];
// Sorts disaster string by a preference
export function sortDisasterStrings(input: string): string {
  if (!(input && typeof input == 'string')) {
    return input
  }
  const words = input.split(',');

  // Sort the words based on the index in the preferenceOrder array
  words.sort((a, b) => {
    const indexA = preferenceOrder.indexOf(a.trim());
    const indexB = preferenceOrder.indexOf(b.trim());
    
    // If the word is not in the preferenceOrder, put it at the end
    const adjustedIndexA = indexA === -1 ? preferenceOrder.length : indexA;
    const adjustedIndexB = indexB === -1 ? preferenceOrder.length : indexB;

    return adjustedIndexA - adjustedIndexB;
  });

  return words.join(',');
}

export function getDisastersDiff(
  allDisasters: string,
  currentDisasters: string
) {
  const allDisastersArr = checkAndSplitByComma(allDisasters)
  const currentDisastersArr = checkAndSplitByComma(currentDisasters)
  const newDisasters = allDisastersArr.filter(
    (item) =>
      // items in all disasters that does not include in current disaster
      !currentDisastersArr.includes(item)
  )

  return sortDisasterStrings(getCommaSeparatedFromArr(removeDuplicatesArr(newDisasters)))
}

export function isDisasterValNaN(value: unknown): boolean {
  // Cannot use Number.isNaN, since it is coming in string: "NaN"
  return typeof value === "string" && value === "NaN"
}
