import React, { useMemo, useReducer } from 'react'
import { Trans } from 'react-i18next'
import { useNavigation } from 'react-navi'
import i18next from 'i18next'

import { useLogoutState } from '../../hooks/auth/useLogoutState'
import { useHeaderState } from '../../hooks/useHeaderState'

import {
  roleNames,
  routesConstant,
} from '../../appConstants'

import DisasterPreventionItemsControlMenu from '../../pages/newDashboard/DisasterPreventionItemsControlMenu'
import DisasterRegistrationControlPanel from '../../pages/newDashboard/DisasterRegistrationControlPanel'

import hsseLogo from '../../assets/images/hsse-logo.svg'
import iconGlobalLogo from '../../assets/images/icn-global.svg'
import iconArrowDownLogo from '../../assets/images/icn-arrow-down.svg'
import iconOutlineBuildingLogo from '../../assets/images/icn-outline-building.svg'
import iconUserLogo from '../../assets/images/icn-user.svg'
import type { Map as MapLibreMap } from 'maplibre-gl'
import { SetState } from '../../data/types/common'
import { isGuestUser, useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { localStorageUtils } from '../../utils/localStorageUtils'
import { useLatestDisasterStatus } from '../../hooks/queries/useLatestDisasterStatus'
import { isEarthquakeDisasterPresent, isFireOrGasDisasterPresent, isTsunamiDisasterPresent } from '../../utils/disasterRiskManagement/disaster'
import { useDisasterArea } from '../../hooks/queries/useDisasterArea'

// Need to change the name if needed to Header after UI related changes are done
const NavigationHeader = ({
  mapObject,
  preventionItemsToggle,
  setPreventionItemsToggle,
  setPreventionItemsToggleDisabled,
  preventionItemsToggleDisabled,
  headerRef,
}: {
  mapObject?: MapLibreMap | null
  preventionItemsToggleDisabled?: boolean
  preventionItemsToggle?: boolean
  setPreventionItemsToggle?: SetState<boolean>
  setPreventionItemsToggleDisabled?: SetState<boolean>
  headerRef?: React.RefObject<HTMLElement>
}) => {
  // userHeaderState hook
  const {
    changeLanguage,
    tenantListOptions,
    activeTenantId,
    currentTenantId,
    userName,
    userEmail,
    handleTenantSwitch,
  } = useHeaderState()

  const { userInfoData, tenantsList } = useAuth()

  const { handleLogout } = useLogoutState()
  const idToken = localStorageUtils.getIdToken()
  const isTokenPresent = !!idToken

  const navigation = useNavigation()

  const { app_admin, general_user } = roleNames

  const { role: roleName } = useAuth()
  const [idxLanguageSelect, reRenderLanguageSelectMenu] = useReducer(
    (i) => i + 1,
    0
  ) // for force re-rendering language dropdown
  const [idxTenantSelect, reRenderTenantSelectMenu] = useReducer(
    (i) => i + 1,
    0
  ) // for force re-rendering language dropdown

  //  check if current route is same as qr code page
  const isQRCodePage =
    window.location.pathname === routesConstant.APPLICATION_QR_CODE

  // Get active tenant name
  const activeTenantData =
    tenantListOptions.length &&
    tenantListOptions.find((tenant) => tenant.value === activeTenantId)

  const redirectToLanding = () => {
    navigation.navigate(
      isTokenPresent ? routesConstant.LANDING_PAGE : routesConstant.USER_LOGIN
    )
  }

  const hideDropdownList = (id: string) => {
    const dropdownElementClassList = document.getElementById(id)?.classList
    dropdownElementClassList?.remove('uk-open')
  }

  const {
    data: latestDisasterStatus,
    isLoading: latestDisasterStatusLoading,
    error: latestDisasterStatusError,
  } = useLatestDisasterStatus()

  const {
    data: disasterAreaResponse,
    isLoading: disasterAreaLoading,
    error: disasterAreaError,
  } = useDisasterArea()

  const hasAnyDisaster = !!latestDisasterStatus?.data.status
  const earthquakeDisasterPresent = useMemo(() => hasAnyDisaster && isEarthquakeDisasterPresent(latestDisasterStatus), [hasAnyDisaster, latestDisasterStatus]);
  const tsunamiDisasterPresent = useMemo(() => hasAnyDisaster && isTsunamiDisasterPresent(latestDisasterStatus), [hasAnyDisaster, latestDisasterStatus])
  const hasFireOrGasDisaster = useMemo(() => hasAnyDisaster && isFireOrGasDisasterPresent(latestDisasterStatus), [hasAnyDisaster, latestDisasterStatus])

  const hasMarker =
    !!disasterAreaResponse?.data.status &&
    !!disasterAreaResponse?.data.hazard_point.geometry

  const UserNameLabel = isGuestUser(userInfoData) ? <Trans>LABELS.GUEST_USER</Trans> : ( userName || <Trans>LABELS.POWER_PLANT_USER</Trans> )

  return (
    <nav
      ref={headerRef}
      data-uk-sticky
      data-uk-navbar
      className="uk-container uk-container-expand uk-container-pader pt-24 pb-24 bg-grey-80 header"
    >
      <div className="uk-flex uk-flex-top uk-flex-between uk-width-1-1">
        <div className="uk-flex uk-flex-middle uk-flex-column uk-flex-left hsse-logo">
          <div className="uk-width-1-1">
            <a onClick={redirectToLanding}>
              <img src={hsseLogo} alt="HSSE-logo" className="logo" />
            </a>
          </div>
          {roleName === app_admin && !isQRCodePage && (
            <div className="uk-flex uk-flex-middle">
              {!hasAnyDisaster ? (
                <div className="uk-text-bold font-38 mt-28 color-white normal-mode-heading">
                  <Trans>TITLES.NORMAL_MODE</Trans>
                </div>
              ) : (
                <>
                  <div className="uk-text-bold font-38 mt-28 color-white normal-mode-heading">
                    <Trans>TITLES.DISASTER_MODE</Trans>
                  </div>
                  {
                    // If location needs to be registered
                    (hasFireOrGasDisaster && !hasMarker) ? (
                      <p className="sub-title">
                        <Trans>TITLES.DISASTER_LOCATION</Trans>
                      </p>
                    ) : null
                  }
                  
                  {
                    // if evacuation guidance is needed
                    (earthquakeDisasterPresent || tsunamiDisasterPresent) || (hasFireOrGasDisaster && hasMarker) ? (
                      <p className="sub-title">
                        <Trans>TITLES.EVACUATION_GUIDANCE_SUPPORT</Trans>
                      </p>
                    ) : null
                  }
                </>
              )}
            </div>
          )}
        </div>
        <div className="media-right">
          <ul className="uk-nav uk-nav-default uk-flex uk-flex-middle top-item acn-btns">
            {/*<--------- Language Selection --------->*/}
            <li className="uk-active uk-position-relative mr-66 uk-language">
              <a
                id="language-dropdown-toggler"
                className="column-gap-15 uk-padding-remove-top"
              >
                <img
                  // src="../../assets/images/icn-global.svg"
                  src={iconGlobalLogo}
                  width="40"
                  height="40"
                  className="icn-global icn-primary"
                  alt="language"
                />
                <span className="font-37">
                  <Trans>
                    {i18next.language === 'ja'
                      ? 'LABELS.JAPANESE'
                      : i18next.language === 'en'
                      ? 'LABELS.ENGLISH'
                      : 'LABELS.LANGUAGE'}
                  </Trans>
                </span>
                <img
                  // src="../../assets/images/icn-arrow-down.svg"
                  src={iconArrowDownLogo}
                  className="icn-down-arrow"
                  width="24"
                  height="24"
                  alt="language"
                />
              </a>
              <div
                id="language-dropdown"
                className="uk-light uk-arrow uk-arrow-bottom-right uk-position-absolute uk-dropdown-comman custom-dropdown"
                data-uk-dropdown="pos:bottom-right; mode:click; offset:15;"
                key={idxLanguageSelect}
              >
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li>
                    <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer">
                      <span className="font-30 label-text">
                        <Trans>LABELS.JAPANESE</Trans>
                      </span>
                      <input
                        className="uk-radio uk-margin-auto-left"
                        type="radio"
                        name="japanese-radio-btn"
                        onChange={() => {
                          changeLanguage('ja')
                          reRenderLanguageSelectMenu()
                        }}
                        checked={i18next.language === 'ja'}
                      />
                    </label>
                  </li>
                  <li>
                    <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer mt-18 cursor-not-allowed">
                      <span className="font-30 color-grey-50 label-text">
                        <Trans>LABELS.ENGLISH</Trans>
                      </span>
                      <input
                        className="uk-radio uk-margin-auto-left"
                        type="radio"
                        name="english-radio-btn"
                        onChange={() => {
                          // changeLanguage('en')
                          reRenderLanguageSelectMenu()
                        }}
                        checked={i18next.language === 'en'}
                        disabled
                      />
                    </label>
                  </li>
                </ul>
              </div>
            </li>
            {/*<-------------  Tenant Selection --------->*/}
            <li className="uk-active uk-position-relative mr-58 uk-power-plant">
              <a className="column-gap-15 uk-padding-remove-top">
                <img
                  // src="../../assets/images/icn-outline-building.svg"
                  src={iconOutlineBuildingLogo}
                  width="40"
                  height="40"
                  className="outline-building icn-primary"
                  alt="language"
                />
                <span className="font-35">
                  {activeTenantData ? activeTenantData.label : ''}
                </span>
                <img
                  // src="../../assets/images/icn-arrow-down.svg"
                  src={iconArrowDownLogo}
                  className="icn-down-arrow"
                  width="24"
                  height="24"
                  alt="language"
                />
              </a>
              <div
                key={idxTenantSelect}
                id="tenant-list"
                className="uk-light uk-arrow uk-arrow-top-right power-plant min-w-554 uk-dropdown-comman custom-dropdown"
                data-uk-dropdown="pos:top-right; mode:click; offset:15;"
              >
                <ul className="uk-nav uk-navbar-dropdown-nav" key={activeTenantId}>
                  {tenantListOptions.map((tenant, index) => {
                    return (
                      <li key={`${index}-${tenant.value}`}>
                        <a>
                          <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer">
                            <span className="font-32">{tenant.label}</span>
                            <input
                              className="uk-radio uk-margin-auto-left"
                              type="radio"
                              name="radio1"
                              defaultChecked={tenant.value === activeTenantId}
                              onClick={() => {
                                if (currentTenantId !== tenant.value) {
                                  handleTenantSwitch.mutate({
                                    user_preference: tenant.value,
                                  })
                                  reRenderTenantSelectMenu()
                                }
                              }}
                            />
                          </label>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </li>
            {/*<------------- User Profile Section --------->*/}
            <li className="uk-active mr-50 uk-aki-suzuki">
              <a className="column-gap-15 uk-padding-remove-top">
                <span className="font-35">{UserNameLabel}</span>
                <img
                  // src="../../assets/images/icn-arrow-down.svg"
                  src={iconArrowDownLogo}
                  className="icn-down-arrow"
                  width="24"
                  height="24"
                  alt=""
                />
              </a>
              <div
                className="uk-light uk-arrow uk-arrow-top-right uk-dropdown-comman custom-dropdown"
                data-uk-dropdown="pos:top-right; mode:click; offset:15;"
              >
                <div className="uk-flex uk-flex-middle">
                  <div className="user-pic mr-50">
                    <img
                      // src="../../assets/images/icn-user.svg"
                      src={iconUserLogo}
                      alt="user"
                    />
                  </div>
                  <div className="user-details">
                    <h3 className="font-44 pt-14 uk-margin-remove">
                      {UserNameLabel}
                    </h3>
                    <p className="font-22 mt-5">
                      <a>{userEmail}</a>
                    </p>
                    <button
                      onClick={() => handleLogout(navigation)}
                      className="uk-button uk-btn-logout uk-width-1-1 font-16 min-w-224"
                    >
                      <Trans>BUTTONS.LOGOUT</Trans>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            {/*<------------- Hamburger Menu --------->*/}
            {/* Don't display hamburger menu for QR code page and if the user has general user role */}
            {!isQRCodePage && tenantsList && tenantsList.length > 0 && (roleName !== general_user) && !isGuestUser(userInfoData) && (
              <DisasterPreventionItemsControlMenu
                mapObject={mapObject}
                preventionItemsToggle={preventionItemsToggle}
                setPreventionItemsToggle={setPreventionItemsToggle}
                preventionItemsToggleDisabled={preventionItemsToggleDisabled}
                setPreventionItemsToggleDisabled={
                  setPreventionItemsToggleDisabled
                }
              />
            )}
          </ul>
          {/* <----------- Disaster Registration Control Panel ----------> */}
          {roleName === app_admin && !isQRCodePage ? (
            <DisasterRegistrationControlPanel />
          ) : null}
        </div>
      </div>
    </nav>
  )
}

export default NavigationHeader
