import React, { useEffect, useMemo, useReducer } from 'react'

import { useQuery } from '@tanstack/react-query'
import type { Map as MapLibreMap } from 'maplibre-gl'
import { Trans, useTranslation } from 'react-i18next'
import {
  FETCH_USER_SAFETY_LIST_AFTER_MINUTES,
  USER_EVACUATION_STATUSES,
  USER_SAFETY_STATUSES,
} from '../../appConstants'
import iconArrowDownLogo from '../../assets/images/icn-arrow-down.svg'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import type { ReducerDispatch, SetState } from '../../data/types/common'
import {
  ALL,
  FilterAction,
  FilterState,
  OTHER,
  UserSafetyStatus,
  UserTablePos,
} from '../../data/types/DisasterRiskManagement'
import { useUserMapLocation } from '../../hooks/disasterRiskManagement/useSafetyListTableData'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import { convertNumberToContainMinimunThreeDigits } from '../../utils/commonUtils'
import {
  filterUserSafetyList,
  getDefaultUserStatus,
} from '../../utils/disasterRiskManagement/filterSafetyList'
import { DEFAULT_POSITION, getBounds } from '../../pages/newDashboard'
import ReactDOM from 'react-dom'
import { useUserLocationsQuery } from '../../hooks/queries/useUserLocationsQuery'
import { useLatestDisasterStatus } from '../../hooks/queries/useLatestDisasterStatus'
import { useEvacuationHistory } from '../../hooks/queries/useEvacuationHistory'

export const initialEvacuationFilter = Object.values(USER_EVACUATION_STATUSES)
  .filter((item) => item !== USER_EVACUATION_STATUSES.STATUS_BLANK)
  .map((item) => ({ label: item, selected: true }))

export const initialUserSafetyFilter = Object.values(
  USER_SAFETY_STATUSES
).map((item) => ({ label: item, selected: false }))

export const initialPartnerCompanySelection = [
  { label: ALL, selected: true },
  { label: OTHER, selected: false },
]

export const initialDepartmentSelection = [
  { label: ALL, selected: true },
  { label: OTHER, selected: false },
]

export const initialFilterState: FilterAction['payload']['initialState'] = {
  partnerListSelection: initialPartnerCompanySelection,
  evacuationListSelection: initialEvacuationFilter,
  safetyListSelection: initialUserSafetyFilter,
  departmentFilterSelection: initialDepartmentSelection
}
const ZERO_NUMBER_STRING = '000'

type FooterProps = {
  shouldSafetyListTableDisplay: boolean
  setShouldSafetyListTableDisplay: React.Dispatch<React.SetStateAction<boolean>>
  preventionItemsToggle: boolean
  setPreventionItemsToggle: SetState<boolean>
  mapObject: null | MapLibreMap
  preventionItemsToggleDisabled: boolean
  filterState: FilterState
  updateFilterState: ReducerDispatch<FilterAction>
  footerRef: React.RefObject<HTMLDivElement>
  setBounds: SetState<ReturnType<typeof getBounds>>
  isTableMinimize: boolean
  setPosition: SetState<UserTablePos>
  position: UserTablePos
}

const NavigationFooter: React.FC<FooterProps> = ({
  shouldSafetyListTableDisplay,
  setShouldSafetyListTableDisplay,
  preventionItemsToggle,
  setPreventionItemsToggle,
  mapObject,
  preventionItemsToggleDisabled,
  filterState,
  updateFilterState,
  footerRef,
  setBounds,
  isTableMinimize,
  setPosition,
  position
}) => {
  const { currentTenantId } = useAuth()

  // for force re-rendering company selection dropdown
  const [idxCompanySelect, reRenderCompanySelectMenu] = useReducer(
    (i) => i + 1,
    0
  )

  // for force re-rendering department selection dropdown
  const [idxDepartmentSelect, reRenderDepartmentSelectMenu] = useReducer(
    (i) => i + 1,
    0
  )

  // for force re-rendering evacuation status selection dropdown
  const [
    idxEvacuationStatusSelect,
    reRenderEvacuationStatusSelectMenu,
  ] = useReducer((i) => i + 1, 0)

  // get the latest disaster info to check if disaster is registered
  const { data: latestDisasterStatus } = useLatestDisasterStatus()

  const disasterId = latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined

  const { data: safetyListApiResponse } = useQuery({
    queryKey: ['user-safety-list', disasterId],
    enabled: !!disasterId,
    refetchInterval: FETCH_USER_SAFETY_LIST_AFTER_MINUTES * 60 * 1000,
    queryFn: () =>
      DisasterService.fetchUserSafetyList(Number(disasterId), currentTenantId),
  })


  const { data: locationsApiData } = useUserLocationsQuery()

  const { data: evacuationApiData, error: evacuationApiError } = useEvacuationHistory()

  const { t } = useTranslation()

  const safetyListData = useMemo(() => safetyListApiResponse?.data || [], [safetyListApiResponse?.data])
  useEffect(() => {
    updateFilterState({
      type: 'UPDATE_PARTNER_FILTER',
      payload: { newSafetyListData: safetyListData },
    })
    updateFilterState({
      type: 'UPDATE_DEPARTMENT_FILTER',
      payload: { newSafetyListData: safetyListData },
    })
  }, [safetyListData, updateFilterState])

  const isDisasterRegistered = !!(latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined)

  const everyEvacuationOptionSelected = filterState.evacuationListSelection.every(
    (item) => item.selected
  )

  // Consider 'ALL' and 'OTHER' as separate companies
  const everyPartnerCompanySelected = filterState.partnerListSelection.some(
    (partner) => partner.label === ALL && partner.selected
  )

  // Consider 'ALL' and 'OTHER' as separate departments
  const everyDepartmentSelected = filterState.departmentFilterSelection.some(
    (department) => department.label === ALL && department.selected
  )

  const currentlySelectedEvacuation = everyEvacuationOptionSelected
    ? ALL
    : filterState.evacuationListSelection.find((item) => item.selected)?.label

  const currentlySelectedPartnerCompany = everyPartnerCompanySelected
    ? ALL
    : filterState.partnerListSelection.find((item) => item.selected)

  const currentlySelectedDepartment = everyDepartmentSelected
  ? ALL
  : filterState.departmentFilterSelection.find((item) => item.selected)

  useEffect(() => {
    if (!isDisasterRegistered) {
      setShouldSafetyListTableDisplay(false)
      // Re-initialize the filter states on disaster cancel
      updateFilterState({
        type: 'RESET_FILTER',
        payload: {
          initialState: initialFilterState,
        },
      })
    }
  }, [isDisasterRegistered, setShouldSafetyListTableDisplay, updateFilterState])

  const {
    filteredByPartnersDepartmentsEvacuationStatusAndSafetyStatus,
    filteredByPartnersAndEvacuationStatus,
    filteredByPartners,
  } = useMemo(() => {
    return filterUserSafetyList({
      evacuationHistoryRes: evacuationApiData?.data,
      filterState,
      safetyListData: safetyListApiResponse?.data,
    })
  }, [evacuationApiData?.data, filterState, safetyListApiResponse?.data])
  const userLocationsData = locationsApiData?.data
  const evacuationHistoryError = evacuationApiError

  useUserMapLocation({
    userSafetyListData: filteredByPartnersDepartmentsEvacuationStatusAndSafetyStatus,
    userLocationsData,
    // evacuationHistoryData,
    evacuationHistoryError: evacuationApiError as any,
    // tableData,
    // setTableData,
    mapObject,
  })

  const seriousInjuryUsers = useMemo(() => {
    return filteredByPartners.filter(
      (user) =>
        getDefaultUserStatus(user.user_status as UserSafetyStatus) ===
        USER_SAFETY_STATUSES.SERIOUS_INJURY
    )
  }, [filteredByPartners])
  const minorInjuryUsers = useMemo(() => {
    return filteredByPartners.filter(
      (user) =>
        getDefaultUserStatus(user.user_status as UserSafetyStatus) ===
        USER_SAFETY_STATUSES.MINOR_INJURY
    )
  }, [filteredByPartners])
  const unansweredUsers = useMemo(() => {
    return filteredByPartners.filter(
      (user) =>
        getDefaultUserStatus(user.user_status as UserSafetyStatus) ===
        USER_SAFETY_STATUSES.NOT_ANSWERED
    )
  }, [filteredByPartners])
  const safeUsers = useMemo(() => {
    return filteredByPartners.filter(
      (user) =>
        getDefaultUserStatus(user.user_status as UserSafetyStatus) ===
        USER_SAFETY_STATUSES.SAFE
    )
  }, [filteredByPartners])

  const partnerUsersWithEvacuationComplete = useMemo(() => {
    const evacuationCompleteUsers =
      (evacuationApiData?.data?.evacuation_history || [])
        ?.filter(
          (item) =>
            item.evacuation_status ===
            USER_EVACUATION_STATUSES.EVACUATION_COMPLETED
        )
        ?.map((item) => item.user_id) ?? []
    return filteredByPartners.filter((item) => {
      const userId = item.user_id
      return evacuationCompleteUsers.includes(userId)
    })
  }, [evacuationApiData?.data?.evacuation_history, filteredByPartners])

  return (
    <div
      ref={footerRef}
      className="uk-container uk-container-pader footer pt-14 pb-14 uk-position-fixed uk-bottom-0 uk-position-z-index uk-width-1-1 color-white bg-grey-80"
    >
      <div className="uk-flex uk-flex-middle column-gap-60 f-child">
        <div className="font-36 text-wrap situation-heding">
          <Trans>TITLES.STATUS</Trans>
        </div>
        <div className="uk-flex uk-flex-flex-start column-gap-20 uk-pader uk-flex-1">
          <div className="uk-flex column-gap-20 both-dropdown-nav uk-flex-1 uk-flex-between">
            {/* <--- Company Filter ---> */}
            <div
              className={`min-w-200 f-box flex-grow ${
                isDisasterRegistered ? '' : 'opacity-half'
              }`}
            >
              <ul
                className={`uk-nav uk-nav-default ${
                  isDisasterRegistered ? '' : 'cursor-not-allowed'
                }`}
              >
                <li
                  className={`uk-position-relative ${
                    isDisasterRegistered ? '' : 'cursor-not-allowed'
                  }`}
                >
                  <a
                    className={`${
                      isDisasterRegistered
                        ? ''
                        : 'cursor-not-allowed pointer-events-none'
                    }`}
                  >
                    <span className="font-22 mr-20">
                      <Trans>TITLES.COMPANY</Trans>
                    </span>
                    <img
                      // src="../../assets/images/icn-arrow-down-grey.svg"
                      src={iconArrowDownLogo}
                      width="25"
                      height="25"
                      alt=""
                      className="icn-down-arrow"
                    />
                  </a>
                  <div
                    id="company-selection"
                    className="uk-light uk-arrow uk-arrow-bottom-right left-auto custom-dropdown f-custom-dropdown"
                    data-uk-dropdown="pos:bottom-right; mode:click; offset:15;"
                    key={idxCompanySelect}
                  >
                    <div className="company-selection font-32">
                      <Trans>LABELS.COMPANY_SELECTION</Trans>
                    </div>
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <a>
                          <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer">
                            <span className="font-32">
                              <Trans>LABELS.SELECT_ALL</Trans>
                            </span>
                            <div className="uk-margin-auto-left">
                              <input
                                className="uk-radio"
                                type="radio"
                                name="ALL_PARTNER_COMPANIES"
                                checked={everyPartnerCompanySelected}
                                readOnly
                                onClick={() => {
                                  updateFilterState({
                                    type: 'TOGGLE_PARTNER_FILTER',
                                    payload: {
                                      toggleItem: {
                                        label: ALL,
                                        selected: everyPartnerCompanySelected,
                                      },
                                      newSafetyListData: safetyListData,
                                    },
                                  })
                                  reRenderCompanySelectMenu()
                                }}
                              />
                            </div>
                          </label>
                        </a>
                      </li>
                      {filterState.partnerListSelection
                        .filter(
                          (item) => item.label !== OTHER && item.label !== ALL
                        )
                        .map((item) => {
                          const label = item.label
                          const selected = item.selected
                          return (
                            <li key={label}>
                              <a>
                                <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer mt-17">
                                  <span className="font-32 text-nowrap">
                                    {label}
                                  </span>
                                  <div className="uk-margin-auto-left">
                                    <input
                                      className="uk-radio"
                                      type="radio"
                                      name={label}
                                      // don't select if ALL option is selected
                                      checked={
                                        selected && !everyPartnerCompanySelected
                                      }
                                      readOnly
                                      onClick={() => {
                                        updateFilterState({
                                          type: 'TOGGLE_PARTNER_FILTER',
                                          payload: {
                                            toggleItem: {
                                              label,
                                              selected,
                                            },
                                            newSafetyListData: safetyListData,
                                          },
                                        })
                                        reRenderCompanySelectMenu()
                                      }}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          )
                        })}
                      {filterState.partnerListSelection
                        .filter((item) => item.label === 'OTHER')
                        .map((item) => {
                          const label = item.label
                          const selected = item.selected
                          return (
                            <li key={label}>
                              <a>
                                <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer mt-17">
                                  <span className="font-32 text-nowrap">
                                    <Trans>LABELS.OTHER</Trans>
                                  </span>
                                  <div className="uk-margin-auto-left">
                                    <input
                                      className="uk-radio"
                                      type="radio"
                                      name={label}
                                      // don't select if ALL option is selected
                                      checked={
                                        selected && !everyPartnerCompanySelected
                                      }
                                      readOnly
                                      onClick={() => {
                                        updateFilterState({
                                          type: 'TOGGLE_PARTNER_FILTER',
                                          payload: {
                                            toggleItem: {
                                              label,
                                              selected,
                                            },
                                            newSafetyListData: safetyListData,
                                          },
                                        })
                                        reRenderCompanySelectMenu()
                                      }}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </li>
              </ul>
              <p className="f-box-value uk-text-bold mt-5 uk-margin-remove-bottom filter-ellipses-label">
                {currentlySelectedPartnerCompany === ALL ? (
                  <Trans>LABELS.SELECT_ALL</Trans>
                ) : currentlySelectedPartnerCompany?.label === OTHER ? (
                  <Trans>LABELS.OTHER</Trans>
                ) : currentlySelectedPartnerCompany?.label ? (
                  currentlySelectedPartnerCompany?.label
                ) : null}
              </p>
            </div>

            {/* <--- Department Filter ---> */}
            <div
              className={`min-w-150 f-box flex-grow ${
                isDisasterRegistered ? '' : 'opacity-half'
              }`}
            >
              <ul
                className={`uk-nav uk-nav-default ${
                  isDisasterRegistered ? '' : 'cursor-not-allowed'
                }`}
              >
                <li
                  className={`uk-position-relative ${
                    isDisasterRegistered ? '' : 'cursor-not-allowed'
                  }`}
                >
                  <a
                    className={`${
                      isDisasterRegistered
                        ? ''
                        : 'cursor-not-allowed pointer-events-none'
                    }`}
                  >
                    <span className="font-22 mr-20">
                      <Trans>TITLES.AFFILIATION</Trans>
                    </span>
                    <img
                      // src="../../assets/images/icn-arrow-down-grey.svg"
                      src={iconArrowDownLogo}
                      width="25"
                      height="25"
                      alt=""
                      className="icn-down-arrow"
                    />
                  </a>
                  <div
                    id="department-selection"
                    className="uk-light uk-arrow uk-arrow-bottom-right left-auto custom-dropdown f-custom-dropdown"
                    data-uk-dropdown="pos:bottom-right; mode:click; offset:15;"
                    key={idxDepartmentSelect}
                  >
                    <div className="company-selection font-32">
                      <Trans>LABELS.DEPARTMENT_SELECTION</Trans>
                    </div>
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <a>
                          <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer">
                            <span className="font-32">
                              <Trans>LABELS.SELECT_ALL</Trans>
                            </span>
                            <div className="uk-margin-auto-left">
                              <input
                                className="uk-radio"
                                type="radio"
                                name="ALL_DEPARTMENTS"
                                checked={everyDepartmentSelected}
                                readOnly
                                onClick={() => {
                                  updateFilterState({
                                    type: 'TOGGLE_DEPARTMENT_FILTER',
                                    payload: {
                                      toggleItem: {
                                        label: ALL,
                                        selected: everyDepartmentSelected,
                                      },
                                    },
                                  })
                                  reRenderDepartmentSelectMenu()
                                }}
                              />
                            </div>
                          </label>
                        </a>
                      </li>
                      {filterState.departmentFilterSelection
                        .filter(
                          (item) => item.label !== OTHER && item.label !== ALL
                        )
                        .map((item) => {
                          const label = item.label
                          const selected = item.selected
                          return (
                            <li key={label}>
                              <a>
                                <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer mt-17">
                                  <span className="font-32 text-nowrap">
                                    {label}
                                  </span>
                                  <div className="uk-margin-auto-left">
                                    <input
                                      className="uk-radio"
                                      type="radio"
                                      name={label}
                                      // don't select if ALL option is selected
                                      checked={
                                        selected && !everyDepartmentSelected
                                      }
                                      readOnly
                                      onClick={() => {
                                        updateFilterState({
                                          type: 'TOGGLE_DEPARTMENT_FILTER',
                                          payload: {
                                            toggleItem: {
                                              label,
                                              selected,
                                            },
                                          },
                                        })
                                        reRenderDepartmentSelectMenu()
                                      }}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          )
                        })}
                      {filterState.departmentFilterSelection
                        .filter((item) => item.label === 'OTHER')
                        .map((item) => {
                          const label = item.label
                          const selected = item.selected
                          return (
                            <li key={label}>
                              <a>
                                <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer mt-17">
                                  <span className="font-32 text-nowrap">
                                    <Trans>LABELS.OTHER</Trans>
                                  </span>
                                  <div className="uk-margin-auto-left">
                                    <input
                                      className="uk-radio"
                                      type="radio"
                                      name={label}
                                      // don't select if ALL option is selected
                                      checked={
                                        selected && !everyDepartmentSelected
                                      }
                                      readOnly
                                      onClick={() => {
                                        updateFilterState({
                                          type: 'TOGGLE_DEPARTMENT_FILTER',
                                          payload: {
                                            toggleItem: {
                                              label,
                                              selected,
                                            },
                                          },
                                        })
                                        reRenderDepartmentSelectMenu()
                                      }}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </li>
              </ul>
              <p className="f-box-value uk-text-bold mt-5 uk-margin-remove-bottom filter-ellipses-label">
                {currentlySelectedDepartment === ALL ? (
                  <Trans>LABELS.SELECT_ALL</Trans>
                ) : currentlySelectedDepartment?.label === OTHER ? (
                  <Trans>LABELS.OTHER</Trans>
                ) : currentlySelectedDepartment?.label ? (
                  currentlySelectedDepartment?.label
                ) : null}
              </p>
            </div>
            {/* --- Evacuation status --- */}
            <div
              className={`min-w-200 f-box flex-grow ${
                isDisasterRegistered ? '' : 'opacity-half'
              }`}
            >
              <ul
                className={`uk-nav uk-nav-default ${
                  isDisasterRegistered ? '' : 'cursor-not-allowed'
                }`}
              >
                <li
                  className={`uk-position-relative ${
                    isDisasterRegistered ? '' : 'cursor-not-allowed'
                  }`}
                >
                  <a
                    className={`${
                      isDisasterRegistered
                        ? ''
                        : 'cursor-not-allowed pointer-events-none'
                    }`}
                  >
                    <span className="font-22 mr-20">
                      <Trans>TITLES.EVACUATION_STATUS</Trans>
                    </span>
                    <img
                      // src="../../assets/images/icn-arrow-down-grey.svg"
                      src={iconArrowDownLogo}
                      width="25"
                      height="25"
                      alt=""
                      className="icn-down-arrow"
                    />
                  </a>
                  <div
                    id="evacuation-status-select"
                    className="uk-light uk-arrow uk-arrow-bottom-right left-auto custom-dropdown f-custom-dropdown"
                    data-uk-dropdown="pos:bottom-right; mode:click; offset:15;"
                    key={idxEvacuationStatusSelect}
                  >
                    <div className="company-selection font-23">
                      <Trans>LABELS.EVACUATION_STATUS_SELECTION</Trans>
                    </div>
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <a>
                          <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer">
                            <span className="font-32">
                              <Trans>LABELS.SELECT_ALL</Trans>
                            </span>
                            <div className="uk-margin-auto-left">
                              <input
                                className="uk-radio"
                                type="radio"
                                name="ALL_EVACUATION"
                                checked={everyEvacuationOptionSelected}
                                readOnly
                                onClick={() => {
                                  updateFilterState({
                                    type: 'TOGGLE_EVACUATION_FILTER',
                                    payload: {
                                      toggleItem: {
                                        label: ALL,
                                        selected: everyEvacuationOptionSelected,
                                      },
                                    },
                                  })
                                  reRenderEvacuationStatusSelectMenu()
                                }}
                              />
                            </div>
                          </label>
                        </a>
                      </li>
                      {filterState.evacuationListSelection.map((item) => {
                        const { label, selected } = item
                        return (
                          <li key={label}>
                            <a>
                              <label className="uk-width-1-1 uk-flex uk-flex-middle cursor-pointer mt-17">
                                <span className="font-32 text-nowrap">
                                  {t(`LABELS.${label}`)}
                                </span>
                                <div className="uk-margin-auto-left">
                                  <input
                                    className="uk-radio"
                                    type="radio"
                                    name={label}
                                    // don't select if ALL option is selected
                                    checked={
                                      selected && !everyEvacuationOptionSelected
                                    }
                                    readOnly
                                    onClick={() => {
                                      updateFilterState({
                                        type: 'TOGGLE_EVACUATION_FILTER',
                                        payload: {
                                          toggleItem: {
                                            label,
                                            selected,
                                          },
                                        },
                                      })
                                      reRenderEvacuationStatusSelectMenu()
                                    }}
                                  />
                                </div>
                              </label>
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </li>
              </ul>
              <p className="f-box-value uk-text-bold mt-5 uk-margin-remove-bottom">
                {currentlySelectedEvacuation === ALL ? (
                  <Trans>LABELS.SELECT_ALL</Trans>
                ) : currentlySelectedEvacuation ? (
                  t(`LABELS.${currentlySelectedEvacuation}`)
                ) : (
                  <Trans>TITLES.EVACUATION_STATUS_SELECTION</Trans>
                )}
              </p>
            </div>
          </div>
          <div className="uk-flex column-gap-40 uk-overflow-scroll max-w-976 sec-part">
            {filterState.safetyListSelection.map((item, idx) => {
              const { label, selected } = item
              const lastIdx = filterState.safetyListSelection.length - 1
              const noOfUsers =
                label === USER_SAFETY_STATUSES.SERIOUS_INJURY
                  ? seriousInjuryUsers?.length
                  : label === USER_SAFETY_STATUSES.MINOR_INJURY
                  ? minorInjuryUsers?.length
                  : label === USER_SAFETY_STATUSES.NOT_ANSWERED
                  ? unansweredUsers?.length
                  : safeUsers?.length

              return (
                <div
                  className={`f-box ${lastIdx === idx && 'min-w-110'} ${
                    isDisasterRegistered
                      ? ''
                      : 'cursor-not-allowed opacity-half'
                  }`}
                  key={label}
                >
                  <label
                    className={
                      isDisasterRegistered ? '' : 'pointer-events-none'
                    }
                  >
                    <input
                      className={`uk-checkbox uk-checkbox-custom uk-margin-small-right ${
                        isDisasterRegistered ? '' : 'pointer-events-none'
                      }`}
                      type="checkbox"
                      checked={selected}
                      onChange={(e) => {
                        // toggle
                        updateFilterState({
                          type: 'TOGGLE_SAFETY_FILTER',
                          payload: { toggleItem: item },
                        })
                      }}
                    />
                    <span className="font-22">{t(`LABELS.${label}`)}</span>
                  </label>
                  <div className="mt-10 uk-flex uk-flex-middle bottom-text">
                    <span className="uk-text-bold font-34">
                      {convertNumberToContainMinimunThreeDigits(noOfUsers) ||
                        ZERO_NUMBER_STRING}
                    </span>
                    <span className="font-20 mt-10">
                      <Trans>LABELS.PEOPLE</Trans>
                    </span>
                  </div>
                </div>
              )
            })}
            {/* --- Evacuation Completed/ Visitors --- */}
            <div
              className={`evacuation-main f-box ${
                isDisasterRegistered ? '' : 'opacity-half'
              }`}
            >
              <div className="uk-flex uk-flex-middle evacuation">
                <span className="font-22">
                  <Trans>LABELS.EVACUATION_COMPLETED</Trans>
                </span>
                <span className="font-22 ml-35">
                  <Trans>LABELS.VISITORS</Trans>
                </span>
              </div>
              <div className="mt-5 uk-flex uk-flex-middle count-text">
                <span className="uk-text-bold font-38">
                  {convertNumberToContainMinimunThreeDigits(
                    partnerUsersWithEvacuationComplete?.length
                  ) || ZERO_NUMBER_STRING}
                </span>
                <span className="font-20 mt-10 sup-text">
                  <Trans>LABELS.PEOPLE</Trans>
                </span>
                <span className="uk-margin-small-left uk-margin-small-right font-32">
                  /
                </span>
                <span className="uk-text-bold font-38">
                  {convertNumberToContainMinimunThreeDigits(
                    filteredByPartners?.length
                  ) || ZERO_NUMBER_STRING}
                </span>
                <span className="font-20 mt-10 sup-text">
                  <Trans>LABELS.PEOPLE</Trans>
                </span>
              </div>
            </div>
            {/* --- Safety List Toggler --- */}
            <div
              className={`uk-text-center switch-btn f-box min-w-100 ${
                isDisasterRegistered ? '' : 'opacity-half'
              }`}
            >
              <span className="font-18">
                <Trans>LABELS.LIST</Trans>{' '}
                {shouldSafetyListTableDisplay ? 'ON' : 'OFF'}
              </span>
              <h3 className="uk-margin-remove-bottom mt-15">
                <input
                  type="checkbox"
                  id="safety-list-table-toggle"
                  disabled={!isDisasterRegistered}
                  checked={shouldSafetyListTableDisplay}
                  onChange={(e) => {
                    ReactDOM.flushSync(function() {
                      setShouldSafetyListTableDisplay(prev => !prev)
                    })
                    const bounds_ = getBounds(!isTableMinimize)
                    
                    if (!bounds_) {
                      return
                    }
                    
                    if (
                      isTableMinimize &&
                      // if not changed already, change default position
                      (position.y === DEFAULT_POSITION.y &&
                        position.x === DEFAULT_POSITION.x)
                    ) {
                      setBounds(bounds_)
                      setPosition({ x: 0, y: bounds_.top }) // (to go to top right of screen when FIRST table is shown)
                    }
                  }}
                  hidden
                />
                <label
                  className={`switch ${
                    !isDisasterRegistered ? 'cursor-not-allowed disabled' : ''
                  }`}
                  htmlFor="safety-list-table-toggle"
                ></label>
              </h3>
            </div>
            {/* --- Prevention Items Toggler --- */}
            <div
              className={`uk-text-center switch-btn f-box min-w-160 ${
                preventionItemsToggleDisabled ? 'opacity-half' : ''
              }`}
            >
              <span className="font-19">
                <Trans>LABELS.EQUIPMENT_ICON</Trans>{' '}
                {preventionItemsToggle ? 'ON' : 'OFF'}
              </span>
              <h3 className="uk-margin-remove-bottom mt-15">
                <input
                  type="checkbox"
                  hidden
                  id="prevention-items-toggle"
                  checked={preventionItemsToggle}
                  disabled={preventionItemsToggleDisabled}
                  onChange={() => {
                    setPreventionItemsToggle((prev) => !prev)
                  }}
                />
                <label
                  className={`switch ${
                    preventionItemsToggleDisabled
                      ? 'cursor-not-allowed disabled'
                      : ''
                  }`}
                  htmlFor="prevention-items-toggle"
                  aria-disabled={preventionItemsToggleDisabled}
                ></label>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavigationFooter

export function filterReducer(
  current: FilterState,
  action: FilterAction
): FilterState {
  const { type, payload } = action
  const { newSafetyListData } = payload

  if (type === 'UPDATE_PARTNER_FILTER') {
    if (!newSafetyListData) {
      throw new Error('new safety list not provided')
    }

    const safetyListData = newSafetyListData
    const uniqueCompanies = ([
      ...new Set(safetyListData.map((item) => item.company)),
    ].filter(
      // filter null and empty strings
      (company) => !!company
    ) as unknown) as string[]

    const newCompanies = uniqueCompanies
      .map((item) => {
        return {
          label: item,
          selected: true, // by default true
        }
      })
      .filter(
        (item) =>
          !current.partnerListSelection.some(
            (currentItem) => currentItem.label === item.label
          )
      )

    // add new companies to existing ones
    return {
      ...current,
      partnerListSelection: [...current.partnerListSelection, ...newCompanies],
    }
  }

  if (type === 'TOGGLE_PARTNER_FILTER') {
    const toggleItem = payload.toggleItem
    if (!toggleItem) {
      throw new Error('params missing for toggling menu items - partner filter')
    }
    if (!newSafetyListData) {
      throw new Error('new safety list not provided')
    }

    const newPartnerListSelection = current.partnerListSelection.map((item) => {
      const isCurrentItem = item.label === toggleItem.label
      const selected = item.selected
      const nextSelected = isCurrentItem ? !selected : false

      if (toggleItem.label === ALL) {
        // if already selected, do nothing
        if (toggleItem.selected) {
          return item
        }

        const newAllSelected = !toggleItem.selected

        // if this is the OTHER option and it is currently selected and ALL option needs to be selected
        // then unselect OTHER option
        if (item.label == OTHER && item.selected && newAllSelected) {
          return {
            ...item,
            selected: false
          }
        }

        // toggle all checkboxes
        return {
          ...item,
          selected: newAllSelected,
        }
      }


      return {
        ...item,
        // once an item is selected,
        // should not allow to unselect that specific item
        selected: isCurrentItem && selected ? selected : nextSelected,
      }
    })
    
    const newPartnerSelectionLabels = newPartnerListSelection.filter(item => item.selected).map(item => item.label);
    const includeOther = newPartnerSelectionLabels.some(item => item === OTHER)

    // On change of partner, give department list of selected companies only
    const departmentsToInclude = newSafetyListData.filter(item => {
      // if 'other' is selected in company
      // return departments of those items which do not have a company
      if (includeOther) {
        return !item.company
      }
      return item.company && newPartnerSelectionLabels.includes(item.company)
    }).map(item => item.department)

    const nextStateDepartmentFilter = getUniqueValues(newSafetyListData.map(item => {
      return {
        label: item.department,
        selected: true
      }
    }).filter(item => !!item.label) as unknown as FilterState['departmentFilterSelection'], 'label')

    // push ALL, OTHER to the department values from API
    nextStateDepartmentFilter.push(...initialDepartmentSelection);
    
    const departmentFilterSelectionTemp = nextStateDepartmentFilter.filter(item => {
      // ALL, OTHER are always in list items as an option to select
      if (item.label == OTHER || item.label == ALL) {
        return true
      }

      return departmentsToInclude.includes(item.label)
    })
    
    const departmentFilterSelection = departmentFilterSelectionTemp.map(item => {
      // all selected initially
      if (item.label !== OTHER) {
        return {
          ...item,
          selected: true
        }
      }

      return {
        ...item,
        selected: false
      }
    })

    return {
      ...current,
      // single select behavior for items
      partnerListSelection: newPartnerListSelection,
      departmentFilterSelection: departmentFilterSelection
    }
  }

  if (type === 'TOGGLE_DEPARTMENT_FILTER') {
    const toggleItem = payload.toggleItem
    if (!toggleItem) {
      throw new Error('params missing for toggling menu items - partner filter')
    }
    return {
      ...current,
      // single select behavior for items
      departmentFilterSelection: current.departmentFilterSelection.map((item) => {
        const isCurrentItem = item.label === toggleItem.label
        const selected = item.selected
        const nextSelected = isCurrentItem ? !selected : false

        if (toggleItem.label === ALL) {
          // if already selected, do nothing
          if (toggleItem.selected) {
            return item
          }

          const newAllSelected = !toggleItem.selected

          // if this is the OTHER option and it is currently selected and ALL option needs to be selected
          // then unselect OTHER option
          if (item.label == OTHER && item.selected && newAllSelected) {
            return {
              ...item,
              selected: false
            }
          }

          // toggle all checkboxes
          return {
            ...item,
            selected: newAllSelected,
          }
        }

        return {
          ...item,
          // once an item is selected,
          // should not allow to unselect that specific item
          selected: isCurrentItem && selected ? selected : nextSelected,
        }
      }),
    }
  }

  if (type === 'TOGGLE_EVACUATION_FILTER') {
    const toggleItem = payload.toggleItem
    if (!toggleItem) {
      throw new Error(
        'params missing for toggling menu items - evacuation filter'
      )
    }
    return {
      ...current,
      // single select behavior for items
      evacuationListSelection: current.evacuationListSelection.map((item) => {
        const isCurrentItem = item.label === toggleItem.label
        const selected = item.selected
        const nextSelected = isCurrentItem ? !selected : false

        if (toggleItem.label === ALL) {
          // if already selected, do nothing
          if (toggleItem.selected) {
            return item
          }

          const newAllSelected = !toggleItem.selected

          // toggle all checkboxes
          return {
            ...item,
            selected: newAllSelected,
          }
        }

        return {
          ...item,
          // once an item is selected,
          // should not allow to unselect that specific item
          selected: isCurrentItem && selected ? selected : nextSelected,
        }
      }),
    }
  }

  if (type === 'TOGGLE_SAFETY_FILTER') {
    const toggleItem = payload.toggleItem
    if (!toggleItem) {
      throw new Error('params missing for toggling menu items - safety filter')
    }
    return {
      ...current,
      // single select behavior for items
      safetyListSelection: current.safetyListSelection.map((item) => {
        return {
          ...item,
          // once an item is selected,
          // should not allow to unselect that specific item
          selected:
            item.label === toggleItem.label ? !item.selected : item.selected,
        }
      }),
    }
  }

  if (type === 'RESET_FILTER') {
    const defaultData = payload.initialState
    if (!defaultData) {
      throw new Error('initial state not provided')
    }

    return {
      ...defaultData,
    }
  }

  if (type === 'UPDATE_DEPARTMENT_FILTER') {
    if (!newSafetyListData) {
      throw new Error('new safety list not provided')
    }

    const safetyListData = newSafetyListData
    const uniqueDepartments = ([
      ...new Set(safetyListData.map((item) => item.department)),
    ].filter(
      // filter null and empty strings
      (department) => !!department
    ) as unknown) as string[]

    const newDepartments = uniqueDepartments
      .map((item) => {
        return {
          label: item,
          selected: true, // by default true
        }
      })
      .filter(
        (item) =>
          !current.departmentFilterSelection.some(
            (currentItem) => currentItem.label === item.label
          )
      )

    // add new departments to existing ones
    return {
      ...current,
      departmentFilterSelection: [...current.departmentFilterSelection, ...newDepartments],
    }
  }

  throw new Error(`action type not handled: ${type}`)
}

function getUniqueValues<T>(arr: T[], key: keyof T) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}
