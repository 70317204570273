import type { LngLatLike } from 'maplibre-gl'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import {
  USER_EVACUATION_STATUSES,
  USER_SAFETY_STATUSES,
} from '../../appConstants'

export type FetchDisasterStatusRes = {
  disaster_id: number
  plant_code: string
  created_datetime: string
  updated_datetime: string
  status: boolean
  practice_flag: boolean
  created_by: string
  disaster_type: string
}
export type PostDisasterStatusRes = {
  disaster_id: number
  status: boolean
}
export type PutDisasterStatusRes = {
  disaster_id: number
  status: boolean
}
export type FetchDisasterPlaceRes = {
  status_code: number
  headers: Headers
  body:
    | {
        type: string
        features: {
          type: string
          properties: {
            name: string
          }
          geometry: {
            type: string
            coordinates: number[]
          }
        }[]
      }
    | string
}
export type PostDisasterPlaceRes = {
  status_code: number
  headers: Headers
  body:
    | {
        disaster_id: string
        plant_id: string
        latitude: number
        longitude: number
        status: boolean
      }
    | string
}
export type PutDisasterPlaceRes = {
  status_code: number
  headers: Headers
  body:
    | {
        disaster_id: string
        plant_id: string
        latitude: number
        longitude: number
        status: boolean
      }
    | string
}
export type FetchEvacuationSiteFeatureRes = {
  type: string
  features: EvacuationSiteFeature[]
}
export type FetchUserSafetyListRes = UserSafetyListRes[]
export type UserSafetyListRes = {
  company: string | null
  created_at: string
  department: string | null
  disaster_id?: number
  updated_at: string | null
  user_id: string
  user_name: string
  user_status: string
  evacuation_status?: string
}
export type FetchPowerPlantPartnerRes = {
  plant_id: string
  partner_id: string
  partner_name: string
}[]

export type User = {
  user_id: string
  geometry: PointGeometry
}
export interface FetchUserLocationsRes {
  disaster_id: number
  users: User[]
}
export interface FetchHazardAreasRes {
  status_code: number
  headers: Headers
  body: HazardAreaBody | string
}

export interface FetchWeatherLocationsRes {
  weather: Weather
}
export type Headers = {
  'Content-Type': string
  'Access-Control-Allow-Origin': string
}
export type Operator = {
  latitude: number
  longitude: number
  timestamp?: string
  user_id: string
}
export type Weather = {
  latitude: number
  longitude: number
  wind_direction: number
  wind_speed: number
  wind_speed_unit: string
  created_datetime: string
}
export type EvacuationSiteFeature = {
  type: string
  properties: {
    type: string
    evacuation_site_id: number
    name: string
  }
  geometry: {
    type: string
    coordinates: LngLatLike
  }
}
export type UserLocationFeature = {
  type: 'Feature'
  properties: {
    id: string
    user_id: string
    status: string
    userName?: string
    partnerCompany?: string | null
  }
  geometry: {
    type: 'Point'
    coordinates: LngLatLike
  }
}

export interface HazardAreaBody {
  disaster_id: number
  tenant_id: string
  status: boolean
  disaster_type: string
  hazard_point: {
    geometry: {
      type: string
      coordinates: Array<number>
    }
  }
  hazard_area: {
    geometry: {
      type: string
      coordinates: Array<Array<Array<number>>>
    }[]
  }
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
}
export interface PointGeometry {
  type: 'Point'
  coordinates: [number, number] // Tuple for longitude and latitude
}
export type Polygon = {
  type: 'Polygon'
  coordinates: number[][][] // Array of arrays of coordinate tuples
}
export type LineStringGeometry = {
  type: 'LineString'
  coordinates: number[][][] // Array of arrays of coordinate tuples
}
export type FetchAllowedPowerPlant = {
  plant_code: string
  plant_name: string
  entry_exit_system_plant_code: string
}[]
export type FetchPowerPlantMapCoordinates = {
  status_code: number
  body: {
    plant_code: string
    type: string
    center: {
      type: string
      coordinates: number[]
    }
    controllable_range: {
      type: string
      coordinates: number[]
    }
    geometry: {
      type: string
      coordinates: number[]
    }
  }
}
export type FetchTenantMapCoordinates = {
  tenant_id: string
  tenant_name: string
  map_center: {
    type: string
    coordinates: number[]
  }
  controll_limit_pc: {
    geometry: Polygon
  }
  controll_limit_mobile: {
    geometry: Polygon
  }
  geofence_radius_in_meter: number
  created_at: string
  created_by: string
  updated_at: string | null
  updated_by: string | null
  map_key: { type: string; key: string }[]
  tenant_boundary: Polygon
  coast: LineStringGeometry
}
export type FetchUserEvacuationStatus = {
  display_evacuation_status: boolean
  evacuation_calculation_after_minute: number
  evacuation_history: {
    user_id: string
    distance: string
    evacuation_status: string
  }[]
}

export type DisasterItem = {
  disaster_item_type: string
  disaster_items_list: {
    disaster_item_id: number
    latitude: number
    longitude: number
  }[]
}

export type DisasterItemsResponse = {
  disaster_items: DisasterItem[]
}

export type RegisterDisasterItemsPayload = {
  disaster_item_type: string
  latitude: number
  longitude: number
}

export type EvacuationHistoryResponse = Awaited<
  ReturnType<typeof DisasterService.fetchEvacuationStatus>
>['data']

export type PartnerListFilterItem = {
  label: string
  selected: boolean
}

export type UserEvacuationStatusKey = keyof typeof USER_EVACUATION_STATUSES
export type UserEvacuationStatus =
  | typeof USER_EVACUATION_STATUSES[UserEvacuationStatusKey]
  | typeof ALL
export type EvacuationListItem = {
  label: UserEvacuationStatus
  selected: boolean
}

export type UserSafetyStatusKey = keyof typeof USER_SAFETY_STATUSES
export type UserSafetyStatus = typeof USER_SAFETY_STATUSES[UserSafetyStatusKey]
type userSafetyListItem = { label: UserSafetyStatus; selected: boolean }
type DepartmentListItem = { label: string; selected: boolean }


export type FilterState = {
  partnerListSelection: PartnerListFilterItem[]
  evacuationListSelection: EvacuationListItem[]
  safetyListSelection: userSafetyListItem[]
  departmentFilterSelection: DepartmentListItem[]
}

type ACTIONS =
  | 'UPDATE_PARTNER_FILTER'
  | 'TOGGLE_PARTNER_FILTER'
  | 'TOGGLE_EVACUATION_FILTER'
  | 'TOGGLE_SAFETY_FILTER'
  | 'RESET_FILTER'
  | 'UPDATE_DEPARTMENT_FILTER'
  | 'TOGGLE_DEPARTMENT_FILTER'

type SafetyListPayload = (Omit<PartnerListFilterItem, 'selected' | 'label'> & {
  company: string | null | undefined
  department: string | null | undefined
})[]
export type FilterReducerPayload = {
  newSafetyListData?: SafetyListPayload
  toggleItem?: PartnerListFilterItem
  initialState?: FilterState
}

export type FilterAction = { type: ACTIONS; payload: FilterReducerPayload }
export const ALL = 'ALL'
export const OTHER = 'OTHER'

export type UserTablePos = { x: number, y: number }

export type TSUNAMI_RESPONSE = {
  tsunami: {
    jma_reports_id: string
    date_time: string
    title: string
    status: string
    editorial_office: string
    report_date_time: string
    target_date_time: string
    event_id: string
    info_kind: string
    tenant_id: string
    area_tsunami_code: string
    area_tsunami_name: string
    tsunami_warning_code: string
    tsunami_warning_name: string
    last_tsunami_warning_code: string
    last_tsunami_warning_name: string
    first_height_arrival_time: string
    first_height_condition: string
    max_height: string
    forecast_point_tsunami_code: string
    forecast_point_tsunami_name: string
    forecast_point_tsunami_high_tide_date_time: string
    forecast_point_tsunami_first_height_arrival_time: string
  }
}

export type EARTHQUAKE_RESPONSE = {
  seismic_intensity: {
    jma_reports_id: string
    date_time: string
    title: string
    status: string
    editorial_office: string
    report_date_time: string
    target_date_time: string
    event_id: string
    info_kind: string
    tenant_id: string
    seismic_intensity: string
    area_information_prefecture_earthquake_code: string
    area_information_prefecture_earthquake_name: string
    area_forecast_local_e_code: string
    area_forecast_local_e_name: string
    area_information_city_code: any
    area_information_city_name: any
    point_seismic_intensity_code: any
    point_seismic_intensity_name: any
  }
  earthquake: {
    jma_reports_id: string
    date_time: string
    title: string
    status: string
    editorial_office: string
    report_date_time: string
    target_date_time: string
    event_id: string
    info_kind: string
    origin_time: string
    arrival_time: string
    area_epicenter_code: string
    area_epicenter_name: string
    hypocenter: string
    magnitude: string
    forecast_comment_code: string
    forecast_comment_text: string
  }
}
