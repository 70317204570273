import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { useIsCorrectMapCoordinatesResponse } from './useMapCoordinates'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import { refetchLatestDisasterInterval } from '../../pages/newDashboard/common/refetchLatestDisaster'
import { roleNames, routesConstant } from '../../appConstants'
const { app_admin } = roleNames

export function useLatestDisasterStatus() {
  const { role: roleName, currentTenantId } = useAuth()
  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()

  const isQRCodePage =
    window.location.pathname === routesConstant.APPLICATION_QR_CODE

  return useQuery({
    queryKey: ['disaster-status', currentTenantId],
    enabled:
      !!currentTenantId &&
      !isQRCodePage &&
      roleName === app_admin &&
      mapCoordinatesSuccess,
    queryFn: () => DisasterService.fetchDisasterStatus(currentTenantId),
    refetchInterval: refetchLatestDisasterInterval,
  })
}
