import React, { useEffect, useMemo, useReducer } from 'react'

import iconVolumeLogo from '../../assets/images/icn-volume.svg'
import iconArrowDownGreyLogo from '../../assets/images/icn-arrow-down-grey.svg'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { disasterType, roleNames } from '../../appConstants'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  DisasterService,
  PutDisasterPlaceParam,
  PutDisasterStatusParam,
} from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import ConfirmBoxModal from '../../components/common/ConfirmBoxModal'
import { RegisterToDoubleClickToast } from './RegistertoDoubleClickToast'
import { DisasterEndModal } from './DisasterEndModal'
import { useIsCorrectMapCoordinatesResponse } from '../../hooks/queries/useMapCoordinates'
import { useRegisterDisasterMutation } from '../../hooks/mutation/useRegisterDisasterMutation'
import { useLatestDisasterStatus } from '../../hooks/queries/useLatestDisasterStatus'
import { getDisastersDiff, isEarthquakeDisasterPresent, isFireOrGasDisasterPresent, isTsunamiDisasterPresent } from '../../utils/disasterRiskManagement/disaster'
import { checkAndSplitByComma, getCommaSeparatedFromArr, removeDuplicatesArr } from '../../utils/commonUtils'
import { useDisasterArea } from '../../hooks/queries/useDisasterArea'

type State = {
  selectedDisasterType: 'AMMONIA_LEAKS' | 'FIRE' | null
  disasterRegistrationModal: boolean
  disasterCancellationModal: boolean
  trainingCheckbox: boolean
  afterDisasterCancelModal: boolean
}

const { FIRE, AMMONIA_LEAKS, EARTHQUAKE, TSUNAMI } = disasterType

const DisasterRegistrationControlPanel = () => {
  const { role: RoleData, currentTenantId } = useAuth()
  const isAdmin = RoleData === roleNames.app_admin

  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()

  const {
    data: latestDisasterStatus,
    isLoading: latestDisasterStatusLoading,
    error: latestDisasterStatusError,
  } = useLatestDisasterStatus()

  const hasAnyDisaster = !!latestDisasterStatus?.data.status;
  const hasFireOrGasDisaster = useMemo(() => hasAnyDisaster && isFireOrGasDisasterPresent(latestDisasterStatus), [hasAnyDisaster, latestDisasterStatus])
  const earthquakeDisasterPresent = useMemo(() => hasAnyDisaster && isEarthquakeDisasterPresent(latestDisasterStatus), [hasAnyDisaster, latestDisasterStatus]);
  const tsunamiDisasterPresent = useMemo(() => hasAnyDisaster && isTsunamiDisasterPresent(latestDisasterStatus), [hasAnyDisaster, latestDisasterStatus])
  
  const fireOrGasDisasterId = hasFireOrGasDisaster
    ? latestDisasterStatus?.data.disaster_id
    : undefined
  const practiceFlag = latestDisasterStatus?.data.practice_flag
  const disasterType = latestDisasterStatus?.data.disaster_type ?? null

  // update disaster type select based on disaster type from API
  // or if no disaster, revert to initial value
  useEffect(() => {
    // only if disaster is active
    if (hasAnyDisaster) {
      updateState({
        selectedDisasterType: disasterType as any,
      })
    }

    // if no disaster revert back selected disaster type
    // and training checkbox
    if (!hasAnyDisaster) {
      updateState({
        selectedDisasterType: null,
        trainingCheckbox: false,
      })
    }
  }, [disasterType, hasAnyDisaster])

  const isEnableTrainingCheckbox = useMemo(() => !isFireOrGasDisasterPresent(latestDisasterStatus) && !isEarthquakeDisasterPresent(latestDisasterStatus) && !isTsunamiDisasterPresent(latestDisasterStatus) && isAdmin, [isAdmin, latestDisasterStatus])

  const { t } = useTranslation()
  const [idx, reRenderDisasterSelectMenu] = useReducer((i) => i + 1, 0) // for force re-rendering sandwichMenu

  const additionalClassName = (hasFireOrGasDisaster || !isAdmin)
    ? 'cursor-not-allowed'
    : ''

  const disasterTypes = {
    FIRE: t('LABELS.FIRE'),
    AMMONIA_LEAKS: t('LABELS.AMMONIA_LEAKS'),
  }
  const options = Object.entries(disasterTypes).map(([key, value]) => ({
    value: key,
    label: value,
  }))

  const [state, updateState] = useReducer(
    function (prev: State, next: Partial<State>) {
      return { ...prev, ...next }
    },
    {
      selectedDisasterType: null,
      disasterRegistrationModal: false,
      disasterCancellationModal: false,
      trainingCheckbox: false,
      afterDisasterCancelModal: false,
    }
  )

  const queryClient = useQueryClient()

  const registerDisaster = useRegisterDisasterMutation()

  const {
    data: disasterAreaResponse,
    isLoading: disasterAreaLoading,
  } = useDisasterArea()

  const hasMarker =
    !!disasterAreaResponse?.data.status &&
    !!disasterAreaResponse?.data.hazard_point.geometry

  const disasterAreaMutation = useMutation({
    mutationFn: (
      body: PutDisasterPlaceParam & {
        tenantId: string
        disasterId: number
        status: boolean
      }
    ) =>
      DisasterService.putDisasterPlace(body.tenantId, body.disasterId, {
        latitude: body.latitude,
        longitude: body.longitude,
        status: body.status,
        disaster_type: body.disaster_type
      }),
    onSuccess(data, variables, context) {
      // refetch only if disaster is status is true
      if (hasFireOrGasDisaster) {
        queryClient.invalidateQueries({
          queryKey: ['disaster-status', currentTenantId],
        })
        // refetch disaster Area (to refresh data and redraw disaster marker)
        queryClient.invalidateQueries({
          queryKey: ['disaster-area', currentTenantId, fireOrGasDisasterId],
        })
      }
    },
  })

  const cancelDisaster = useMutation({
    mutationFn: (body: PutDisasterStatusParam & { disasterId: number }) =>
      DisasterService.putDisasterStatus(
        { status: body.status },
        currentTenantId,
        body.disasterId
      ),
    onSuccess(data, variables, context) {
      // refetch latest disaster status
      queryClient.invalidateQueries({
        queryKey: ['disaster-status', currentTenantId],
      })

      const disasterId = hasAnyDisaster
        ? latestDisasterStatus?.data.disaster_id
        : undefined

      if (!disasterId) {
        return
      }

      // show modal after cancelling disaster
      updateState({ afterDisasterCancelModal: true })
      // hide after 5 seconds
      const timeoutId = setTimeout(() => {
        updateState({ afterDisasterCancelModal: false })
        clearTimeout(timeoutId)
      }, 5000)

      // cancel disaster place if present
      if (hasMarker && fireOrGasDisasterId) {
        disasterAreaMutation.mutate({
          disasterId: fireOrGasDisasterId,
          status: false,
          tenantId: currentTenantId,
          disaster_type: null // no disaster present now
        })
      }
    },
  })

  const showAddDisasterButton = !hasFireOrGasDisaster && (earthquakeDisasterPresent || tsunamiDisasterPresent)

  const disasterLabels = useMemo(() => {
    const labels: string[] = []
    // fire or ammonia cannot occur at once
    if (state.selectedDisasterType?.includes(FIRE)) {
      labels.push(t(`LABELS.FIRE`))
    } else if (state.selectedDisasterType?.includes(AMMONIA_LEAKS)) {
      labels.push(t(`LABELS.AMMONIA_LEAKS`))
    }
    
    if (earthquakeDisasterPresent) {
      labels.push(t(`LABELS.EARTHQUAKE`))
    }

    if (tsunamiDisasterPresent) {
      labels.push(t(`LABELS.TSUNAMI`))
    }


    return labels.join(" / ")

  }, [earthquakeDisasterPresent, state.selectedDisasterType, t, tsunamiDisasterPresent])

  return (
    <div className="uk-flex uk-flex-middle uk-flex-between mt-30 bottom-item">
      {hasFireOrGasDisaster &&
      !hasMarker &&
      !disasterAreaLoading &&
      !latestDisasterStatusLoading ? (
        // if disaster registered but no marker (disaster point location) is present
        // Then present prompt to double click toast
        <RegisterToDoubleClickToast disasterType={disasterType as any} />
      ) : null}
      {state.disasterRegistrationModal ? (
        <ConfirmBoxModal
          data-id="disasterRegistrationModal"
          noAction={() => {
            // hide modal
            updateState({ disasterRegistrationModal: false })
          }}
          yesAction={() => {
            // call disaster register API
            const selectedDisasterType = state.selectedDisasterType
            if (!selectedDisasterType) {
              throw new Error('no disaster type selected')
            }
            updateState({ disasterRegistrationModal: false })
            // if there are already disasters present, append fire or gas type to them
            const currentDisasters = latestDisasterStatus?.data.status ? latestDisasterStatus?.data.disaster_type : undefined;
            const allDisastersArr = checkAndSplitByComma(currentDisasters ?? "")
            allDisastersArr.push(...checkAndSplitByComma(selectedDisasterType))
            const allNewDisasterStr = getCommaSeparatedFromArr(removeDuplicatesArr(allDisastersArr))
            const new_disaster = getDisastersDiff(allNewDisasterStr, currentDisasters ?? "")

            registerDisaster.mutate({
              disaster_type: allNewDisasterStr,
              practice_flag: state.trainingCheckbox,
              new_disaster,
            })
          }}
          messageTitle={
            state.trainingCheckbox
              ? t('TITLES.DISASTER_PRACTICE_CONFIRMATION_TITLES')
              : t('TITLES.DISASTER_CONFIRMATION_TITLES')
          }
          messageBody={
            state.trainingCheckbox
              ? t('TITLES.DISASTER_PRACTICE_CONFIRMATION_CONTENT')
              : t('TITLES.DISASTER_ACTUAL_CONFIRMATION_CONTENT')
          }
        />
      ) : null}
      {state.disasterCancellationModal ? (
        <ConfirmBoxModal
          noAction={() => {
            // hide modal
            updateState({ disasterCancellationModal: false })
          }}
          yesAction={() => {
            const disasterId = latestDisasterStatus?.data.status
              ? latestDisasterStatus?.data.disaster_id
              : undefined
            if (!disasterId) {
              throw new Error('disaster id not found!')
            }

            // hide cancel disaster confirm modal
            updateState({ disasterCancellationModal: false })

            // call disaster cancel API
            cancelDisaster.mutate({
              status: false,
              disasterId,
            })
          }}
          messageTitle={`${t('TITLES.DISASTER_CANCELLATION_CONTENT')}`}
          messageBody={''}
        />
      ) : null}
      {state.afterDisasterCancelModal ? <DisasterEndModal /> : null}
      <ul
        className={`uk-nav uk-nav-default uk-position-relative select-disaster ${additionalClassName} ${mapCoordinatesSuccess ? '' : 'cursor-not-allowed'}`}
      >
        <li className={additionalClassName}>
          <a
            className={`uk-flex-center ${additionalClassName} ${
              (hasFireOrGasDisaster) ? 'pointer-events-none' : ''
            } ${mapCoordinatesSuccess ? '' : 'cursor-not-allowed pointer-events-none'}`}
            aria-expanded="false"
          >
            <span
              // white color when able to select dropdown
              className={`font-24 mr-10 ${
                (hasFireOrGasDisaster) ? 'color-grey-50' : ''
              } ${mapCoordinatesSuccess ? '' : 'color-grey-50'}`}
            >
              {state.selectedDisasterType
                ? (disasterLabels || null)
                : t('LABELS.DISASTER_TYPE_DEFAULT_TEXT_SELECTION')}
            </span>
            <img
              src={iconArrowDownGreyLogo}
              className="uk-position-absolute icn-down-arrow"
              width="34"
              height="34"
              alt=""
            />
          </a>
          <div
            className="uk-light uk-arrow uk-arrow-bottom-right uk-position-absolute uk-width-1-1 uk-dropdown-comman custom-dropdown"
            data-uk-dropdown="pos:bottom-right; mode:click; offset:15;"
            style={{ left: '-10px', top: '63px' }}
            id="select-disaster-type-uk-dropdown"
            key={idx}
          >
            <ul className="uk-nav uk-navbar-dropdown-nav">
              {options.map((option, idx) => {
                const checked = !!(state.selectedDisasterType && state.selectedDisasterType.includes(option.value))
                return (
                  <li key={option.value}>
                    <label
                      className={`uk-width-1-1 uk-flex uk-flex-middle cursor-pointer ${
                        idx !== 0 ? 'mt-20' : ''
                      }`}
                    >
                      <span className="font-28">{option.label}</span>
                      <input
                        className="uk-radio uk-margin-auto-left"
                        type="radio"
                        value={option.value}
                        onChange={(e) => {
                          updateState({
                            selectedDisasterType: e.currentTarget.value as any,
                          })
                          if (!showAddDisasterButton) {
                            reRenderDisasterSelectMenu()
                          }
                        }}
                        checked={checked}
                      />
                    </label>
                  </li>
                )
              })}
              {
                showAddDisasterButton ? (
                  <button
                    className="uk-button uk-button-orange color-white uk-flex uk-flex-middle column-gap-20 mt-20 uk-margin-auto"
                    type="button"
                    disabled={!state.selectedDisasterType || !mapCoordinatesSuccess || hasFireOrGasDisaster}
                    onClick={() => {
                      updateState({ disasterRegistrationModal: true });
                      reRenderDisasterSelectMenu()
                    }}
                  >
                    <img src={iconVolumeLogo} width="40" height="40" alt="HSSE-logo" />
                    <span className="font-24 add-disaster-info">{t('LABELS.ADD_DISASTER_INFO')}</span>
                  </button>
                ) : null
              }
            </ul>
          </div>
        </li>
      </ul>
      <div className="uk-flex uk-flex-middle bottom-right">
        <label
          className={`mr-52 uk-flex uk-flex-middle column-gap-20 ${
            !isEnableTrainingCheckbox ? 'cursor-not-allowed' : ''
          }`}
        >
          <input
            className="uk-checkbox"
            type="checkbox"
            disabled={!isEnableTrainingCheckbox}
            checked={
              !!(
                state.trainingCheckbox ||
                // when disaster is present and
                // when practice flag comes from API, this should be checked
                (hasFireOrGasDisaster && practiceFlag && !isEnableTrainingCheckbox)
              )
            }
            onChange={(e) =>
              updateState({ trainingCheckbox: e.currentTarget.checked })
            }
          />
          <span
            className={`${
              !isEnableTrainingCheckbox
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            } font-24 color-white checkbox-label-text`}
          >
            <Trans>LABELS.TRAINS</Trans>
          </span>
        </label>
        {hasAnyDisaster ? (
          <button
            className="uk-button uk-button-orange color-white uk-flex uk-flex-middle column-gap-20 btn-active-mode"
            type="button"
            disabled={!hasAnyDisaster || !isAdmin}
            onClick={() => updateState({ disasterCancellationModal: true })}
            style={{ padding: '0 38px' }}
          >
            <span className="font-28">{t('BUTTONS.CANCELLATION')}</span>
          </button>
        ) : (
          <button
            className="uk-button uk-button-orange color-white uk-flex uk-flex-middle column-gap-20"
            type="button"
            disabled={!state.selectedDisasterType || !mapCoordinatesSuccess || hasFireOrGasDisaster}
            onClick={() => updateState({ disasterRegistrationModal: true })}
          >
            <img src={iconVolumeLogo} width="40" height="40" alt="HSSE-logo" />
            <span className="font-28">{t('BUTTONS.DISASTER')}</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default DisasterRegistrationControlPanel
