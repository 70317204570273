import type { LineStringGeometry } from '../../data/types/DisasterRiskManagement'
import type { Map as MapLibreMap } from 'maplibre-gl'

let blinkingIntervalId: ReturnType<typeof setInterval> | null // To store the interval for blinking
const blinkingInterval = 500 // Adjust the interval (ms) for faster or slower blinking

// Function to add the polygon line
function addCoastLine(
  map: null | MapLibreMap,
  tenantBoundary: LineStringGeometry
) {
  if (!(tenantBoundary && map)) {
    return
  }

  // Add a source for the polygon (your coordinates)
  map.addSource('line-string', {
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: tenantBoundary,
    },
  })

  // Add a line layer to style the polygon's border (initially visible)
  map.addLayer({
    id: 'line-string-layer',
    type: 'line',
    source: 'line-string',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#FF0000', // Red color
      'line-width': 4, // Thickness of the line
      'line-opacity': 1, // Start with fully visible
    },
  })
}

// Function to start the blinking effect
function startBlinking(map: null | MapLibreMap) {
  if (!map) {
    return
  }

  let opacity = 1 // Initial opacity
  blinkingIntervalId = setInterval(() => {
    // Toggle the opacity between 0 and 1
    opacity = opacity === 1 ? 0 : 1
    map.setPaintProperty('line-string-layer', 'line-opacity', opacity)
  }, blinkingInterval)
}

// Function to stop the blinking effect and keep the line visible
function stopBlinking(map: null | MapLibreMap) {
  if (!map) {
    return
  }

  if (blinkingIntervalId) {
    clearInterval(blinkingIntervalId)
    blinkingIntervalId = null
  }
  // Set the opacity to fully visible
  // map.setPaintProperty('polygon-line-layer', 'line-opacity', 1)
}

// Function to remove the boundary altogether
function removeLineString(map: null | MapLibreMap) {
  if (!map) {
    return
  }

  map.getLayer('line-string-layer') && map.removeLayer('line-string-layer')
  map.getSource('line-string') && map.removeSource('line-string')
  // Stop any blinking if it was running
  if (blinkingIntervalId) {
    clearInterval(blinkingIntervalId)
    blinkingIntervalId = null
  }
}

// Initialize the map and add the polygon line on load
// map.on('load', function () {
// addPolygonLine()
// startBlinking() // Automatically start blinking when the map loads (optional)
// })

export const MapBoundaryUtils = {
  addCoastLine,
  startBlinking,
  stopBlinking,
  removeLineString,
}
