import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import iconArrowUpLogo from '../../assets/images/icn-arrow-up.svg'
import iconCompassLogo from '../../assets/images/icn-compass.svg'
import windCircleLogo from '../../assets/images/icons/icn-no-wind.svg'

import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'

import {
  WIND_SPEED_UNIT,
  ZERO_WIND_DIRECTION,
  WIND_DIRECTION_SUBTRACTION_FACTOR,
  FETCH_WIND_DIRECTION_AFTER_MINUTES,
  DASH,
  disasterType,
  HALF_DASH,
} from '../../appConstants'
import type { Map } from 'maplibre-gl'
import { useIsCorrectMapCoordinatesResponse } from '../../hooks/queries/useMapCoordinates'
import { useTsunamiAndEarthquakeInfo } from '../../hooks/queries/useTsunamiAndEarthquakeInfo'
import { format } from 'date-fns'
import { useLatestDisasterStatus } from '../../hooks/queries/useLatestDisasterStatus'
import { isDisasterValNaN } from '../../utils/disasterRiskManagement/disaster'

type NavigationSidebarProps = {
  mapObject: Map | null
  sidebarRef: React.RefObject<HTMLDivElement>
}

const { TSUNAMI, EARTHQUAKE } = disasterType

const NavigationSidebar: React.FC<NavigationSidebarProps> = ({
  mapObject,
  sidebarRef,
}) => {
  const { currentTenantId } = useAuth()
  const [compassBearing, setCompassBearing] = useState(0)

  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()

  const {
    data: weatherApiData,
    isLoading: isWeatherApiDataLoading,
    error: weatherApiError,
  } = useQuery({
    queryKey: ['weather', currentTenantId],
    enabled: !!currentTenantId && mapCoordinatesSuccess,
    refetchInterval: FETCH_WIND_DIRECTION_AFTER_MINUTES * 60 * 1000,
    queryFn: () => DisasterService.fetchWeatherLocations(currentTenantId),
  })

  const { data, isLoading, error } = useTsunamiAndEarthquakeInfo({});
  const { data: latestDisasterStatus } = useLatestDisasterStatus()
  const currentDisasters = latestDisasterStatus?.data.status ? latestDisasterStatus?.data.disaster_type : undefined;

  const windDirection =
    weatherApiData?.data?.weather?.wind_direction || ZERO_WIND_DIRECTION
  const windSpeed = weatherApiData?.data?.weather?.wind_speed || 0
  const windSpeedUnit =
    weatherApiData?.data?.weather?.wind_speed_unit || WIND_SPEED_UNIT
  const windInfoData = `${windSpeed} ${windSpeedUnit}`

  const seismicIntensity = currentDisasters?.includes(EARTHQUAKE) ? data?.earthquakeRes?.seismic_intensity?.seismic_intensity : undefined
  const tsunamiHeight = currentDisasters?.includes(TSUNAMI) ? data?.tsunamiRes?.max_height : undefined
  const tsunamiEstimatedArrivalTime = currentDisasters?.includes(TSUNAMI) ? data?.tsunamiRes?.forecast_point_tsunami_first_height_arrival_time : undefined

  // set compass bearing
  useEffect(() => {
    function setBearing() {
      if (mapObject) {
        const bearing = mapObject.getBearing()
        setCompassBearing(bearing)
      }
    }

    setBearing()
    mapObject?.on('rotate', setBearing)

    return () => {
      setCompassBearing(0)
      if (mapObject) {
        mapObject.off('rotate', setBearing)
      }
    }
  }, [mapObject])

  return (
    <div
      ref={sidebarRef}
      className="side-menu uk-text-center color-white uk-flex uk-flex-column uk-flex-between"
    >
      <div className="sd-box">
        <h2 className="uk-margin-remove-top uk-margin-remove-bottom uk-text-bold font-30">
          {seismicIntensity && !isDisasterValNaN(isDisasterValNaN) ? seismicIntensity : HALF_DASH}
        </h2>
        <p className="font-22">震度</p>
      </div>
      <div className="sd-box">
        <h2 className="uk-margin-remove-top uk-margin-remove-bottom uk-text-bold font-30">
          {tsunamiHeight && !isDisasterValNaN(tsunamiHeight) ? tsunamiHeight : HALF_DASH}{" "}m
        </h2>
        <p className="uk-margin-remove font-22">{'津波高さ​'}</p>
      </div>
      <div className="sd-box">
        <h2 className="uk-margin-remove uk-text-bold font-34">
          { tsunamiEstimatedArrivalTime ? format(new Date(tsunamiEstimatedArrivalTime), "HH:mm") : "00:00" }
        </h2>
        <p className="uk-margin-remove font-22">
          津波予想
          <br />
          到達時間
        </p>
      </div>
      <div className="sd-box">
        {isWeatherApiDataLoading || weatherApiError ? (
          <>
            <h2 className="uk-margin-remove-bottom uk-text-bold font-38 mt-12">
              {DASH}
            </h2>
          </>
        ) : (
          <>
            <img
              style={{
                transform: `rotate(${
                  windDirection - WIND_DIRECTION_SUBTRACTION_FACTOR
                }deg)`,
              }}
              src={
                windDirection === ZERO_WIND_DIRECTION
                  ? windCircleLogo
                  : iconArrowUpLogo
              }
              alt="wind-direction-img"
              className="icn-up-direcrion"
            />
            <h2 className="uk-margin-remove-bottom uk-text-bold font-38 mt-12">
              {windInfoData}
            </h2>
          </>
        )}
        <p className="uk-margin-remove font-22">風向き</p>
      </div>
      <div className="uk-margin-medium-bottom compass-box">
        <img
          style={{
            transform: `rotate(${-compassBearing}deg`,
          }}
          src={iconCompassLogo}
          alt="compass"
          className="icn-compass"
        />
      </div>
    </div>
  )
}

export default NavigationSidebar
