import { useQuery } from '@tanstack/react-query'
import { useLatestDisasterStatus } from './useLatestDisasterStatus'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import { FETCH_USER_EVACUATION_STATUS_AFTER_MINUTE } from '../../appConstants'
import { useMemo } from 'react'
import {
  isEarthquakeDisasterPresent,
  isFireOrGasDisasterPresent,
  isTsunamiDisasterPresent,
} from '../../utils/disasterRiskManagement/disaster'
import { useDisasterArea } from './useDisasterArea'

export function useEvacuationHistory() {
  const { currentTenantId } = useAuth()

  const { data: latestDisasterStatus } = useLatestDisasterStatus()

  const status = latestDisasterStatus?.data.status
  const disasterId = status ? latestDisasterStatus?.data.disaster_id : undefined

  const { data: disasterAreaData } = useDisasterArea()

  const disasterLocationRegistered = disasterAreaData?.data?.hazard_point
    ?.geometry
    ? disasterAreaData?.data?.hazard_point?.geometry
    : undefined

  const tsunamiDisasterPresent = useMemo(
    () => status && isTsunamiDisasterPresent(latestDisasterStatus),
    [latestDisasterStatus, status]
  )
  const earthquakeDisasterPresent = useMemo(
    () => status && isEarthquakeDisasterPresent(latestDisasterStatus),
    [latestDisasterStatus, status]
  )

  const hasFireOrGasDisaster = useMemo(
    () => !!status && isFireOrGasDisasterPresent(latestDisasterStatus),
    [latestDisasterStatus, status]
  )

  return useQuery({
    queryKey: ['evacuation-history', disasterId],
    // if fire or gas disaster, then fetch after location is registered
    // if tsunami or earthquake disaster present then always fetch evacuation status
    enabled:
      !!disasterId &&
      (hasFireOrGasDisaster
        ? !!disasterLocationRegistered
        : (tsunamiDisasterPresent || earthquakeDisasterPresent)),
    refetchInterval: FETCH_USER_EVACUATION_STATUS_AFTER_MINUTE * 60 * 1000,
    queryFn: () =>
      DisasterService.fetchEvacuationStatus(
        currentTenantId,
        Number(`${disasterId}`)
      ),
  })
}
