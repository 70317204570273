import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import type { PostDisasterStatusParam } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import type { AxiosResponse } from 'axios'
import type { PostDisasterStatusRes } from '../../data/types/DisasterRiskManagement'

export function useRegisterDisasterMutation(onSuccess?: (data: AxiosResponse<PostDisasterStatusRes, any>) => void) {
  const { currentTenantId } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: PostDisasterStatusParam) =>
      DisasterService.postDisasterStatus(body, currentTenantId),
    onSuccess(data, variables, context) {
      // refetch latest disaster status
      queryClient.invalidateQueries({
        queryKey: ['disaster-status', currentTenantId],
      })

      onSuccess?.(data);
    },
  })
}
